import { HttpResponse, delay, http } from 'msw';

import { convertStringToArray, db } from '@/mocks';
import { useEnv } from '@/composables';
import {
  BudgetUserRole,
  CompanyPlanKey,
  CompanyUserStatus,
  CoreUserStatus,
  Currency,
  TransactionStatus,
} from '@/enums';

import type { Company, CompanyUser, CoreUser } from '@/interfaces';

const { apiBaseUrl } = useEnv();

const uuid = 1;

const baseUrl = `${apiBaseUrl}/api/v1/app`;

const DEFAULT_PLAN = {
  name: 'Starter',
  [CompanyPlanKey.StripeInboundAchFee]: '$0',
  [CompanyPlanKey.OutboundAchFee]: '$0.25',
  [CompanyPlanKey.StripeInboundWireFee]: '$2',
  [CompanyPlanKey.OutboundWireFee]: '$10',
  [CompanyPlanKey.MonthlyCardIssuingLimit]: 50,
  [CompanyPlanKey.StripeCardIssuingFee]: '$0.25',
  [CompanyPlanKey.StripeInternationalPaymentFee]: '$0.30',
  [CompanyPlanKey.StripeCurrencyExchangeFee]: '1%',
  [CompanyPlanKey.InvoicePaidFee]: '1%',
  activeCardCountLimit: 10000,
};

/**
 * Пока оставлю в закоментрированном виде
 * Надо будет это дело обновить под budgetUser вместо budget
 */
// const matchUsersWithBudgets = (users: CompanyUser[]) =>
//   users.map(async companyUser => {
//     const budgetUserInBudgets = await db.budgetUsersInBudgets
//       .where('userId')
//       .equals(companyUser.user.id)
//       .toArray();

//     const userBudgets = budgetUserInBudgets.map(async budgetUserInBudget => {
//       const budgetUser = await db.budgetUsers
//         .where('user.id')
//         .equals(budgetUserInBudget.userId)
//         .first();
//       const budget = await db.budgets
//         .where('id')
//         .equals(budgetUserInBudget.budgetId)
//         .first();

//       return {
//         id: budget?.id,
//         name: budget?.name,
//         role: budgetUser?.role,
//       };
//     });

//     return Promise.all(userBudgets).then(budgets => ({
//       ...companyUser,
//       budgets,
//     }));
//   });

/**
 * COMPANY
 */
const matchUsersWithTransactionsPending = (users: CompanyUser[]) => {
  return users.map(async user => {
    const transactionsPending = await db.transactions
      .where(['user.id', 'status'])
      .equals([Number(user.id), TransactionStatus.Pending])
      .toArray();

    const pending = transactionsPending.reduce(
      (acc, transaction) => acc + Number(transaction.amount),
      0,
    );

    return {
      ...user,
      pending: String(pending),
    };
  });
};

export const createCompany = http.post(
  `${baseUrl}/core/company/`,
  async ({ request }) => {
    const {
      name,
      business_incorporated: businessIncorporated,
      scope,
      country,
    } = (await request.json()) as Company;

    const currentUser = await db.userMe.where('id').equals(uuid).first();
    const currentUserCompanies =
      currentUser?.companyUsers.map(companyUser => companyUser.company) || [];

    const company = await db.companies.add({
      name,
      businessIncorporated,
      scope,
      country,
    });

    await db.userMe.update(uuid, {
      companies: [...currentUserCompanies, company],
    });

    const updatedUser = await db.userMe.where('id').equals(uuid).first();

    await delay(500);
    return HttpResponse.json(updatedUser);
  },
);

export const updateCompany = http.patch(
  `${baseUrl}/core/company/`,
  async ({ request }) => {
    const currentUser = await db.userMe.where('id').equals(uuid).first();
    const currentUserCompanies = currentUser?.companies || [];

    const {
      name,
      business_incorporated: businessIncorporated,
      scope,
      country,
    } = (await request.json()) as Company;

    const company = await db.companies.add({
      name,
      businessIncorporated,
      scope,
      country,
    });

    await db.userMe.update(uuid, {
      companies: [...currentUserCompanies, company],
    });

    const updatedUser = await db.userMe.where('id').equals(uuid).first();

    await delay(500);
    return HttpResponse.json(updatedUser);
  },
);

/**
 * COMPANY USERS
 */

export const readCompanyUsers = http.get(
  `${baseUrl}/core/company/user/`,
  async ({ request }) => {
    const url = new URL(request.url);
    const limit = Number(url.searchParams.get('limit') || 25);
    const offset = Number(url.searchParams.get('offset') || 0);
    const search = url.searchParams.get('search') || '';
    const statuses = convertStringToArray('statuses', url, false);
    const roles = convertStringToArray('roles', url, false);
    const includedIds = convertStringToArray('included_ids', url);
    const excludedByBudgetIds = convertStringToArray(
      'excluded_by_budget_ids',
      url,
    );
    const extraFields = convertStringToArray('extra_fields', url, false);
    const userIds = convertStringToArray('user_ids', url);

    const filterByStatus = (companyUser: CompanyUser) => {
      if (!statuses.length) return true;
      return statuses.includes(companyUser.status);
    };

    const filterByRole = (companyUser: CompanyUser) => {
      if (!roles.length) return true;
      return roles.includes(companyUser.role);
    };

    const filterByIncludedIds = (companyUser: CompanyUser) => {
      if (!includedIds.length || offset > 0) return true;

      return includedIds.includes(companyUser.user.id!);
    };

    const filterByIncludedIdsExcept = (companyUser: CompanyUser) => {
      if (!includedIds.length || offset > 0) return true;

      return !includedIds.includes(companyUser.user.id!);
    };

    const filterByExcludedByBudgetId = (companyUser: CompanyUser) => {
      if (!excludedByBudgetIds?.length) return true;

      return !companyUser?.budgetUsers?.some((budget: any) =>
        excludedByBudgetIds.includes(budget.id),
      );
    };

    const count = !userIds.length
      ? await db.companyUsers
          .where('user.fullName')
          .startsWithIgnoreCase(search)
          .filter(filterByStatus)
          .filter(filterByRole)
          .filter(filterByExcludedByBudgetId)
          .count()
      : undefined;

    const includedUsers =
      !userIds.length && includedIds.length
        ? await db.companyUsers
            .where('user.fullName')
            .startsWithIgnoreCase(search)
            .filter(filterByIncludedIds)
            .toArray()
        : [];

    const companyUsersByIds = userIds.length
      ? await db.companyUsers
          .where('user.id')
          .anyOf(userIds)
          .filter(filterByStatus)
          .filter(filterByRole)
          .offset(offset)
          .limit(limit)
          .toArray()
      : [];

    const otherUsers = !userIds.length
      ? await db.companyUsers
          .where('user.fullName')
          .startsWithIgnoreCase(search)
          .filter(filterByIncludedIdsExcept)
          .filter(filterByExcludedByBudgetId)
          .filter(filterByStatus)
          .filter(filterByRole)
          .offset(offset)
          .limit(limit)
          .reverse()
          .sortBy('id')
      : [];

    let results = [...companyUsersByIds, ...includedUsers, ...otherUsers];
    let totalPending;

    if (extraFields.includes('pending')) {
      results = await Promise.all(matchUsersWithTransactionsPending(results));

      totalPending = results.reduce(
        (acc, user) => acc + Number(user.pending),
        0,
      );
    }

    const totalSpend = results.reduce(
      (acc, user) => acc + Number(user.spend),
      0,
    );

    await delay(300);
    return HttpResponse.json({
      results,
      count: userIds.length ? results.length : count,
      totalSpend,
      ...(totalPending && { totalPending }),
    });
  },
);

export const readCompanyUsersTotal = http.get(
  `${baseUrl}/core/company/user/total/`,
  async ({ request }) => {
    const url = new URL(request.url);
    const offset = Number(url.searchParams.get('offset') || 0);
    const search = url.searchParams.get('search') || '';
    const statuses = convertStringToArray('statuses', url, false);
    const roles = convertStringToArray('roles', url, false);
    const includedIds = convertStringToArray('included_ids', url);
    const excludedByBudgetIds = convertStringToArray(
      'excluded_by_budget_ids',
      url,
    );
    const extraFields = convertStringToArray('extra_fields', url, false);

    const filterByStatus = (companyUser: CompanyUser) => {
      if (!statuses.length) return true;
      return statuses.includes(companyUser.status);
    };

    const filterByRole = (companyUser: CompanyUser) => {
      if (!roles.length) return true;
      return roles.includes(companyUser.role);
    };

    const filterByIncludedIds = (companyUser: CompanyUser) => {
      if (!includedIds.length || offset > 0) return true;

      return includedIds.includes(companyUser.user.id!);
    };

    const filterByIncludedIdsExcept = (companyUser: CompanyUser) => {
      if (!includedIds.length || offset > 0) return true;

      return !includedIds.includes(companyUser.user.id!);
    };

    const filterByExcludedByBudgetId = (companyUser: CompanyUser) => {
      if (!excludedByBudgetIds?.length) return true;

      return !companyUser?.budgetUsers?.some((budget: any) =>
        excludedByBudgetIds.includes(budget.id),
      );
    };

    const includedUsers = includedIds.length
      ? await db.companyUsers
          .where('user.fullName')
          .startsWithIgnoreCase(search)
          .filter(filterByIncludedIds)
          .toArray()
      : [];

    const otherUsers = await db.companyUsers
      .where('user.fullName')
      .startsWithIgnoreCase(search)
      .filter(filterByIncludedIdsExcept)
      .filter(filterByExcludedByBudgetId)
      .filter(filterByStatus)
      .filter(filterByRole)
      .toArray();

    let allUsers = [...includedUsers, ...otherUsers];
    let totalPending;

    if (extraFields.includes('pending')) {
      allUsers = await Promise.all(matchUsersWithTransactionsPending(allUsers));

      totalPending = allUsers.reduce(
        (acc, user) => acc + Number(user.pending),
        0,
      );
    }

    const totalSpend = allUsers.reduce(
      (acc, user) => acc + Number(user.spend),
      0,
    );
    const result = {
      total: {
        spend: totalSpend.toFixed(2).toString(),
        pending: totalPending?.toFixed(2).toString() || '0',
        currency: Currency.Usd,
      },
      details: [
        {
          spend: totalSpend.toFixed(2).toString(),
          pending: totalPending?.toFixed(2).toString() || '0',
          currency: Currency.Usd,
        },
      ],
    };

    await delay(300);
    return HttpResponse.json(result);
  },
);

export const readCompanyUserById = http.get(
  `${baseUrl}/core/company/user/:id/`,
  async ({ params }) => {
    const userById = await db.companyUsers
      .where('user.id')
      .equals(Number(params.id))
      .first();

    await delay(500);
    return HttpResponse.json(userById);
  },
);

export const updateCompanyUser = http.patch(
  `${baseUrl}/core/company/user/:id/`,
  async ({ request, params }) => {
    const { status, role } = await request.json();
    const id = Number(params.id);

    await db.companyUsers.update(id, {
      ...(status && { status }),
      ...(role && { role }),
    });
    const updatedCompanyUser = await db.companyUsers
      .where('id')
      .equals(id)
      .first();

    await delay(500);
    return HttpResponse.json(updatedCompanyUser);
  },
);

export const deleteCompanyUser = http.delete(
  `${baseUrl}/core/company/user/:id/`,
  async ({ params }) => {
    const id = Number(params.id);

    await db.companyUsers.delete(id);

    await delay(500);
    return HttpResponse.json({ message: 'Delete was successful' });
  },
);

export const createCompanyUserInvite = http.post(
  `${baseUrl}/core/company/user/invite/`,
  async ({ request }) => {
    const body = await request.json();
    const {
      email,
      company_role: companyRole,
      budget_id: budgetId,
      budget_role: budgetRole,
    } = body;

    const userId = await db.users.add({
      avatar: '',
      firstName: email,
      fullName: email,
      status: CoreUserStatus.New,
    });

    const user = (await db.users.get(userId)) as CoreUser;

    let budget = null;

    if (budgetId) {
      budget = await db.budgets.get(budgetId);

      await db.budgetUsers.add({
        user,
        budget,
        role: budgetRole ?? BudgetUserRole.Member,
        limits: [],
        createdAt: new Date(),
      });

      await db.budgetUsersInBudgets.add({
        userId,
        budgetId,
      });

      await db.budgets.update(budgetId, {
        userCount: budget?.userCount ? budget.userCount + 1 : 1,
      });
    }

    await db.companyUsers.add({
      role: companyRole,
      user,
      status: CompanyUserStatus.Invited,
      spend: '0',
      ...(budgetId && {
        budgets: [
          {
            id: budgetId,
            name: budget?.name,
            role: budgetRole ?? BudgetUserRole.Member,
          },
        ],
      }),
    });

    await delay(500);
    return HttpResponse.json(body);
  },
);

export const createCompanyUserReinvite = http.post(
  `${baseUrl}/core/company/user/:id/reinvite/`,
  async () => {
    await delay(1300);
    return new HttpResponse(null, { status: 201 });
  },
);

/**
 * PLAN
 */

export const readCompanyPlan = http.get(
  `${baseUrl}/core/company/plan/`,
  async () => {
    await delay(300);
    return HttpResponse.json(DEFAULT_PLAN);
  },
);
