import { faker } from '@faker-js/faker';

import {
  CompanyComplianceStatus,
  CompanyKybStatus,
  CompanyStatus,
  CompanyUserRole,
  CompanyUserStatus,
} from '@/enums';
import type {
  BudgetUser,
  CompanyUser,
  CoreUser,
  CoreUserMeCompany,
} from '@/interfaces';

import { getRandomItemFromArray } from '@/mocks';
import { AllSeedsData } from '@/seeds';

export const genCompany = (): CoreUserMeCompany => {
  return {
    id: 1,
    name: getRandomItemFromArray(AllSeedsData.companyNames),
    status: CompanyStatus.Approved,
    auth0Id: 'auth0Id',
    canSendFunds: true,
    kybStatus: CompanyKybStatus.Approved,
    complianceStatus: CompanyComplianceStatus.Approved,
  };
};

export const genCompanyUsers = (
  users: CoreUser[],
  budgetUsers: BudgetUser[],
): CompanyUser[] => {
  const userCompanyRoles = Object.values(CompanyUserRole);

  return users.map(user => ({
    id: user.id,
    user,
    permissions: {
      read: true,
      update: true,
      destroy: true,
    },
    status:
      Math.floor(Math.random() * 10) > 3
        ? CompanyUserStatus.Active
        : CompanyUserStatus.Blocked,
    role:
      user.id === 1
        ? CompanyUserRole.Owner
        : userCompanyRoles[
            Math.floor(Math.random() * (userCompanyRoles.length - 1) + 1)
          ],
    budgetUsers: budgetUsers.filter(item => item.user.id === user.id),
    spend: faker.commerce.price({ min: 100, max: 20000 }),
  }));
};
