import { faker } from '@faker-js/faker';

export const getRandomItemFromArray = <T>(array: T[]): T => {
  return array[faker.number.int({ min: 0, max: array.length - 1 })];
};

export const genLimits = (array: string[]) => {
  const limit = getRandomItemFromArray(array);

  if (limit === 'NO_LIMIT') {
    return [];
  }

  return [
    {
      type: limit,
      value: faker.commerce.price({ min: 10000, max: 20000 }),
      spend: faker.commerce.price({ min: 500, max: 12000 }),
    },
  ];
};

export const convertStringToArray = (
  param: string,
  url: URL,
  toNumber = true,
) => {
  if (!param) return [];
  return (
    url.searchParams
      ?.get(param)
      ?.split(',')
      .map(item => (toNumber ? Number(item) : item)) ?? []
  );
};
