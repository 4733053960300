import { HttpResponse, delay, http } from 'msw';
import { faker } from '@faker-js/faker';

import { useEnv } from '@/composables';
import type { CardMockModel } from '@/seeds';
import { convertStringToArray, db } from '@/mocks';

import { BudgetUserRole, CardStatus, CardType, Currency } from '@/enums';

const { apiBaseUrl } = useEnv();

const baseUrl = `${apiBaseUrl}/api/v1/app`;

function omit(key: string, obj: Record<string, any>) {
  const { [key]: _, ...http } = obj;
  return http;
}

const matchCardsWithBudgetAndAccount = (cards: CardMockModel[]) => {
  return cards.map(async card => {
    if (!card.budgetId) return card;
    const budget = await db.budgets.where('id').equals(card.budgetId).first();
    const companyAccount = await db.companyAccounts.get(card.companyAccountId);

    return {
      ...omit('budgetId', card),
      budget,
      companyAccount,
    };
  });
};

export const readCards = http.get(
  `${baseUrl}/karta/card/`,
  async ({ request }) => {
    const url = new URL(request.url);
    const limit = Number(url.searchParams.get('limit') || 25);
    const offset = Number(url.searchParams.get('offset') || 0);
    const search = url.searchParams.get('search') || '';
    const statuses = convertStringToArray('statuses', url, false);
    const limitTypes = convertStringToArray('limit_types', url, false);
    const includedIds = convertStringToArray('included_ids', url);
    const userIds = convertStringToArray('user_ids', url);
    const budgetIds = convertStringToArray('budget_ids', url);
    const companyAccountIds = convertStringToArray('company_account_ids', url);
    const ids = convertStringToArray('ids', url);

    const filterByCompanyAccountId = (card: CardMockModel) => {
      if (!companyAccountIds?.length) return true;
      return companyAccountIds.includes(card?.companyAccountId);
    };

    const filterByStatus = (card: CardMockModel) => {
      if (!statuses.length) return true;
      return statuses.includes(card.status);
    };

    const filterByLimitType = (card: CardMockModel) => {
      if (!limitTypes.length) return true;

      return limitTypes.includes(card.limits[0]?.type);
    };

    const filterByIncludedIds = (card: CardMockModel) => {
      if (!includedIds.length || offset > 0) return true;

      return includedIds.includes(card.id);
    };

    const filterByIncludedIdsExcept = (card: CardMockModel) => {
      if (!includedIds.length || offset > 0) return true;

      return !includedIds.includes(card.id);
    };

    const filterByUser = (card: CardMockModel) => {
      if (!userIds?.length) return true;
      return userIds.includes(card.user.id!);
    };

    const filterByBudget = (card: CardMockModel) => {
      if (!budgetIds?.length) return true;
      return budgetIds.includes(card.budgetId);
    };

    const count = !ids.length
      ? await db.cards
          .where('name')
          .startsWithIgnoreCase(search)
          .filter(filterByCompanyAccountId)
          .filter(filterByBudget)
          .filter(filterByUser)
          .filter(filterByStatus)
          .filter(filterByLimitType)
          .count()
      : undefined;

    const includedCards =
      !ids.length && includedIds.length
        ? await db.cards
            .where('name')
            .startsWithIgnoreCase(search)
            .filter(filterByCompanyAccountId)
            .filter(filterByBudget)
            .filter(filterByIncludedIds)
            .toArray()
        : [];

    const cardsByIds = ids.length
      ? await db.cards
          .where('id')
          .anyOf(ids)
          .filter(filterByCompanyAccountId)
          .filter(filterByBudget)
          .toArray()
      : [];

    const otherCards = !ids.length
      ? await db.cards
          .where('name')
          .startsWithIgnoreCase(search)
          .filter(filterByCompanyAccountId)
          .filter(filterByBudget)
          .filter(filterByUser)
          .filter(filterByIncludedIdsExcept)
          .filter(filterByStatus)
          .offset(offset)
          .limit(limit)
          .reverse()
          .sortBy('createdAt')
      : [];

    const allCards = [...includedCards, ...otherCards];

    const results = await Promise.all(
      matchCardsWithBudgetAndAccount(cardsByIds.length ? cardsByIds : allCards),
    );

    const totalSpend = allCards.reduce(
      (acc, card) => acc + Number(card.spend),
      0,
    );

    await delay(300);
    return HttpResponse.json({
      results,
      count: ids.length ? results.length : count,
      totalSpend,
    });
  },
);

export const readCardsTotal = http.get(
  `${baseUrl}/karta/card/total/`,
  async ({ request }) => {
    const url = new URL(request.url);
    const offset = Number(url.searchParams.get('offset') || 0);
    const search = url.searchParams.get('search') || '';
    const statuses = convertStringToArray('statuses', url, false);
    const includedIds = convertStringToArray('included_ids', url);
    const userIds = convertStringToArray('user_ids', url);
    const budgetIds = convertStringToArray('budget_ids', url);
    const companyAccountIds = convertStringToArray('company_account_ids', url);

    const filterByCompanyAccountId = (card: CardMockModel) => {
      if (!companyAccountIds?.length) return true;
      return companyAccountIds.includes(card?.companyAccountId);
    };

    const filterByStatus = (card: CardMockModel) => {
      if (!statuses.length) return true;
      return statuses.includes(card.status);
    };

    const filterByIncludedIdsExcept = (card: CardMockModel) => {
      if (!includedIds.length || offset > 0) return true;

      return !includedIds.includes(card.id);
    };

    const filterByUser = (card: CardMockModel) => {
      if (!userIds?.length) return true;
      return userIds.includes(card.user.id!);
    };

    const filterByBudget = (card: CardMockModel) => {
      if (!budgetIds?.length) return true;
      return budgetIds.includes(card.budgetId);
    };

    const filterByIncludedIds = (card: CardMockModel) => {
      if (!includedIds.length || offset > 0) return true;

      return includedIds.includes(card.id);
    };

    const includedCards = includedIds.length
      ? await db.cards
          .where('name')
          .startsWithIgnoreCase(search)
          .filter(filterByCompanyAccountId)
          .filter(filterByBudget)
          .filter(filterByIncludedIds)
          .toArray()
      : [];

    const otherCards = await db.cards
      .where('name')
      .startsWithIgnoreCase(search)
      .filter(filterByCompanyAccountId)
      .filter(filterByBudget)
      .filter(filterByUser)
      .filter(filterByIncludedIdsExcept)
      .filter(filterByStatus)
      .toArray();

    const allCards = [...includedCards, ...otherCards];

    const totalSpend = allCards.reduce(
      (acc, card) => acc + Number(card.spend),
      0,
    );

    const result = {
      total: {
        spend: totalSpend.toFixed(2).toString(),
        pending: '0',
        currency: Currency.Usd,
      },
      details: [
        {
          spend: totalSpend.toFixed(2).toString(),
          pending: '0',
          currency: Currency.Usd,
        },
      ],
    };

    await delay(300);
    return HttpResponse.json(result);
  },
);

export const readCardById = http.get(
  `${baseUrl}/karta/card/:id/`,
  async ({ params }) => {
    const cardById = await db.cards
      .where('id')
      .equals(Number(params.id))
      .first();

    if (!cardById) {
      await delay(500);
      return HttpResponse.json(cardById);
    }

    const results = await Promise.all(
      matchCardsWithBudgetAndAccount([cardById]),
    );

    await delay(500);
    return HttpResponse.json(results[0]);
  },
);

export const readCardCredentials = http.get(
  `${baseUrl}/karta/card/:id/credentials/`,
  async ({ params }) => {
    const cardCredentials = await db.cardCredentials
      .where('id')
      .equals(Number(params.id))
      .first();

    await delay(500);
    return HttpResponse.json(cardCredentials);
  },
);

export const createCard = http.post(
  `${baseUrl}/karta/card/`,
  async ({ request }) => {
    const { name, limits, budget_id } = await request.json();

    const panLast4 = faker.finance.creditCardNumber('####');

    const budget = await db.budgets.where('id').equals(budget_id).first();
    const user = await db.userMe.toCollection().first();

    const newCardId = await db.cards.add({
      name,
      limits,
      panLast4,
      status: CardStatus.Active,
      type: CardType.Virtual,
      user,
      cardHolder: user!.companyUsers![0].company.name.toUpperCase(),
      spend: '0',
      billingAddress: faker.location.street(),
      createdAt: new Date(),
      budgetId: budget_id,
      companyAccountId: budget?.companyAccount.id as number,
      permissions: {
        read: true,
        update: true,
        destroy: true,
        credentials: true,
      },
    });

    await db.cardCredentials.add({
      id: newCardId,
      pan: `${faker.finance.creditCardNumber('############')}${panLast4}`,
      cvc: faker.finance.creditCardCVV(),
      expiration: '06 / 26',
    });

    await db.budgetUsers.add({
      user,
      role: BudgetUserRole.Manager,
      limits: [],
      createdAt: new Date(),
      permissions: {
        read: true,
        update: true,
        updateToManager: false,
        updateToMember: false,
        destroy: true,
      },
      budget,
    });

    await db.budgetUsersInBudgets.add({
      budgetId: budget_id,
      userId: user,
    });

    const newCard = await db.cards.where('id').equals(newCardId).first();

    await delay(500);
    return HttpResponse.json(newCard);
  },
);

export const updateCard = http.patch(
  `${baseUrl}/karta/card/:id/`,
  async ({ request, params }) => {
    const { status, name, limits, budget_id } = await request.json();
    const id = Number(params.id);

    let budget;
    if (budget_id) {
      budget = await db.budgets.get(budget_id);
    }

    const card = await db.cards.get(id);
    const userMe = await db.userMe.toCollection().first();

    let permissions = card?.permissions ?? {};

    const currentStatus = status ?? card?.status;

    if (card?.status !== status) {
      permissions = {
        read: true,
        credentials:
          card?.user.id === userMe?.id
            ? currentStatus === CardStatus.Active
            : false,
        update: status !== CardStatus.Closed,
        destroy: true,
      };
    }

    await db.cards.update(id, {
      ...(status && { status }),
      ...(name && { name }),
      ...(budget && { budgetId: budget.id }),
      ...(Array.isArray(limits) && { limits }),
      permissions,
    });
    const updatedCard = await db.cards.get(id);

    await delay(500);
    return HttpResponse.json(updatedCard);
  },
);
