export const genFullName = (
  firstName?: string,
  lastName?: string,
  isCompact?: boolean,
): string => {
  if (!firstName && lastName) return `${lastName}`;

  if (firstName && !lastName) return `${firstName}`;

  if (!firstName && !lastName) return '';

  if (lastName && isCompact) return `${firstName} ${lastName[0]}.`;

  return `${firstName} ${lastName}`;
};
