import { HttpResponse, delay, http } from 'msw';
import { omit } from 'lodash-es';

import { faker } from '@faker-js/faker';
import { convertStringToArray, db, getRandomItemFromArray } from '@/mocks';
import { useEnv } from '@/composables';
import {
  CompanyProviderOutcomePaymentMethod,
  Currency,
  TransactionFeeType,
  TransactionStatus,
  TransactionType,
} from '@/enums';

import type {
  CardHolder,
  Transaction,
  TransactionFeeReadResponse,
} from '@/interfaces';

const { apiBaseUrl } = useEnv();

const baseUrl = `${apiBaseUrl}/api/v1/app`;

const matchTransactionsWithCounterparty = (transactions: Transaction[]) => {
  return transactions.map(async transaction => {
    if (!transaction.counterparty) return transaction;
    const counterparty = await db.counterparties.get(
      transaction.counterparty.id,
    );

    if (!counterparty) return transaction;

    return {
      ...transaction,
      counterparty: {
        id: counterparty.id,
        invoicePrefix: counterparty.invoicePrefix,
        businessType: counterparty.businessType,
        name: counterparty.name,
        email: counterparty.email,
        country: counterparty.country,
        logo: counterparty.logo,
        paymentMethods: counterparty.paymentMethods,
      },
    };
  });
};

export const readTransactions = http.get(
  `${baseUrl}/karta/transaction/`,
  async ({ request }) => {
    /**
     * @todo нужно оптимизировать работы с фильтрами и параметрами
     * т.к. они повторяются от метода к методу
     */
    const url = new URL(request.url);
    const limit = Number(url.searchParams.get('limit') || 25);
    const offset = Number(url.searchParams.get('offset') || 0);
    const search = url.searchParams.get('search') || '';
    const companyAccountIds = convertStringToArray('company_account_ids', url);
    const cardIds = convertStringToArray('card_ids', url);
    const userIds = convertStringToArray('user_ids', url);
    const budgetIds = convertStringToArray('budget_ids', url);
    const statuses = convertStringToArray('statuses', url, false);
    const types = convertStringToArray('types', url, false);
    const withFeeOnly = url.searchParams.get('with_fee_only') || false;

    const filterByStatus = (transaction: Transaction) => {
      if (!statuses.length) return true;
      return statuses.includes(transaction.status);
    };

    const filterByType = (transaction: Transaction) => {
      if (!types.length) return true;
      return types.includes(transaction.type);
    };

    const filterByCompanyAccountId = (transaction: Transaction) => {
      if (!companyAccountIds?.length) return true;
      return companyAccountIds.includes(transaction?.companyAccount?.id);
    };

    const filterByCard = (transaction: Transaction) => {
      if (!cardIds?.length) return true;
      if (!transaction.card?.id) return false;
      return cardIds.includes(transaction.card.id);
    };

    const filterByUser = (transaction: Transaction) => {
      if (!userIds?.length) return true;
      if (!transaction?.user?.id) return false;
      return userIds.includes(transaction.user.id);
    };

    const filterByBudget = (transaction: Transaction) => {
      if (!budgetIds.length) return true;
      if (!transaction?.budget?.id) return false;
      return budgetIds.includes(transaction.budget.id);
    };

    const count = await db.transactions
      .where('merchant')
      .startsWithIgnoreCase(search)
      .filter(filterByCompanyAccountId)
      .filter(filterByBudget)
      .filter(filterByUser)
      .filter(filterByStatus)
      .filter(filterByType)
      .filter(filterByCard)
      .count();

    const transactions = await db.transactions
      .where('merchant')
      .startsWithIgnoreCase(search)
      .filter(filterByCompanyAccountId)
      .filter(filterByBudget)
      .filter(filterByUser)
      .filter(filterByStatus)
      .filter(filterByType)
      .filter(filterByCard)
      .offset(offset)
      .limit(limit)
      .toArray();

    let results = await Promise.all(
      matchTransactionsWithCounterparty(transactions),
    );

    if (withFeeOnly) {
      results = results.filter(transaction => transaction.hasFee);
    }

    await delay(300);
    return HttpResponse.json({
      results,
      count,
    });
  },
);

export const readTransactionsTotal = http.get(
  `${baseUrl}/karta/transaction/total/`,
  async ({ request }) => {
    const url = new URL(request.url);
    const search = url.searchParams.get('search') || '';
    const companyAccountId = Number(url.searchParams.get('company_account_id'));
    const cardIds = convertStringToArray('card_ids', url);
    const userIds = convertStringToArray('user_ids', url);
    const budgetIds = convertStringToArray('budget_ids', url);
    const statuses = convertStringToArray('statuses', url, false);
    const types = convertStringToArray('types', url, false);
    const withFeeOnly = url.searchParams.get('with_fee_only') || false;

    const filterByStatus = (transaction: Transaction) => {
      if (!statuses.length) return true;
      return statuses.includes(transaction.status);
    };

    const filterByType = (transaction: Transaction) => {
      if (!types.length) return true;
      return types.includes(transaction.type);
    };

    const filterByCompanyAccountId = (transaction: Transaction) => {
      if (!companyAccountId) return true;
      return transaction?.companyAccount?.id === companyAccountId;
    };

    const filterByCard = (transaction: Transaction) => {
      if (!cardIds?.length) return true;
      if (!transaction?.card?.id) return false;
      return cardIds.includes(transaction.card.id);
    };

    const filterByUser = (transaction: Transaction) => {
      if (!userIds?.length) return true;
      if (!transaction?.user?.id) return false;
      return userIds.includes(transaction.user.id);
    };

    const filterByBudget = (transaction: Transaction) => {
      if (!budgetIds?.length) return true;
      if (!transaction?.budget?.id) return false;
      return budgetIds.includes(transaction.budget.id);
    };

    let transactions = await db.transactions
      .where('merchant')
      .startsWithIgnoreCase(search)
      .filter(filterByCompanyAccountId)
      .filter(filterByBudget)
      .filter(filterByUser)
      .filter(filterByStatus)
      .filter(filterByType)
      .filter(filterByCard)
      .toArray();

    if (withFeeOnly) {
      transactions = transactions.filter(transaction => transaction.hasFee);
    }

    const totalAmountWithFee = transactions.reduce(
      (acc, transaction) => acc + Number(transaction.totalAmount),
      0,
    );
    const totalAmountWithoutFee = transactions.reduce(
      (acc, transaction) => acc + Number(transaction.amount),
      0,
    );
    const totalFee = totalAmountWithFee - totalAmountWithoutFee;
    const totalIncome = transactions
      .filter(transaction => Number(transaction.amount) > 0)
      .reduce((acc, transaction) => acc + Number(transaction.amount), 0);
    const totalSpend = transactions
      .filter(transaction => Number(transaction.amount) < 0)
      .reduce((acc, transaction) => acc + Number(transaction.amount), 0);
    const totalPending = transactions
      .filter(transaction => transaction.status === TransactionStatus.Pending)
      .reduce((acc, transaction) => acc + Number(transaction.amount), 0);
    const totalDeclined = transactions
      .filter(transaction => transaction.status === TransactionStatus.Declined)
      .reduce((acc, transaction) => acc + Number(transaction.amount), 0);

    const result = {
      total: {
        summary: totalAmountWithFee.toFixed(2).toString(),
        spend: totalSpend.toFixed(2).toString(),
        pending: totalPending.toFixed(2).toString(),
        fee: totalFee.toFixed(2).toString(),
        income: totalIncome.toFixed(2).toString(),
        declined: totalDeclined.toFixed(2).toString(),
        currency: Currency.Usd,
      },
      details: [
        {
          spend: totalSpend.toFixed(2).toString(),
          pending: totalPending.toFixed(2).toString(),
          fee: totalFee.toFixed(2).toString(),
          income: totalIncome.toFixed(2).toString(),
          declined: totalDeclined.toFixed(2).toString(),
          currency: Currency.Usd,
        },
      ],
    };

    await delay(300);
    return HttpResponse.json(result);
  },
);

export const readTransactionsReport = http.get(
  `${baseUrl}/karta/transaction/report/`,
  async () => {
    await delay(300);
    return HttpResponse.json({
      message: 'Transactions was successful reported',
    });
  },
);

export const readTransactionFee = http.get(
  `${baseUrl}/karta/transaction/:id/fee/`,
  async ({ params }) => {
    const transaction = await db.transactions
      .where('id')
      .equals(Number(params.id))
      .first();

    const fees = Number(transaction?.amount) - Number(transaction?.totalAmount);
    const result: TransactionFeeReadResponse = [];

    if (fees !== 0) {
      const count = faker.number.int({ min: 1, max: 3 });
      let arrayOfFeeType = Object.values(TransactionFeeType);
      for (let i = 1; i <= count; i++) {
        arrayOfFeeType = arrayOfFeeType.filter(
          item => !result.map(item => item.type).includes(item),
        );
        const type = getRandomItemFromArray(arrayOfFeeType);
        result.push({ type, value: `-${fees / count}` });
      }
    }

    await delay(500);
    return HttpResponse.json(result);
  },
);

export const createTransactionInternal = http.post(
  `${baseUrl}/karta/transaction/internal/`,
  async ({ request }) => {
    const {
      amount,
      transfer_from: transferFrom,
      transfer_to: transferTo,
    } = await request.json();

    const companyAccountFrom = await db.companyAccounts.get(transferFrom);
    const companyAccountTo = await db.companyAccounts.get(transferTo);

    if (!companyAccountFrom) {
      await delay(500);
      return new HttpResponse(JSON.stringify({ transferFrom: ['Not found'] }), {
        status: 404,
      });
    }

    if (!companyAccountTo) {
      await delay(500);
      return new HttpResponse(JSON.stringify({ transferTo: ['Not found'] }), {
        status: 404,
      });
    }

    if (Number(companyAccountFrom.balance!) < Number(amount)) {
      await delay(500);
      return new HttpResponse(
        JSON.stringify({ amount: ['Incorrect balance'] }),
        {
          status: 422,
        },
      );
    }

    await db.companyAccounts.update(transferFrom, {
      balance: Number(companyAccountFrom.balance!) - Number(amount),
    });
    await db.companyAccounts.update(transferTo, {
      balance: Number(companyAccountTo.balance!) + Number(amount),
    });

    const userMe = await db.userMe.toCollection().first();
    const user = await db.users
      .where('id')
      .equals(userMe?.id as number)
      .first();

    const cardHolder = omit(user, 'username') as CardHolder;

    await db.transactions.add({
      type: TransactionType.InternalTransfer,
      status: TransactionStatus.Settled,
      amount: `-${amount}`,
      totalAmount: `-${amount}`,
      currency: 'USD',
      companyAccount: companyAccountFrom,
      merchant: '',
      authorizedAt: new Date(),
      user: cardHolder,
      hasFee: false,
    });

    await db.transactions.add({
      type: TransactionType.InternalTransfer,
      status: TransactionStatus.Settled,
      amount,
      totalAmount: amount,
      currency: 'USD',
      companyAccount: companyAccountTo,
      merchant: '',
      authorizedAt: new Date(),
      hasFee: false,
    });

    await delay(500);
    return HttpResponse.json();
  },
);

export const createTransactionPayment = http.post(
  `${baseUrl}/karta/transaction/payment/`,
  async ({ request }) => {
    const {
      company_account_id: companyAccountId,
      counterparty_payment_account_id: counterpartyPaymentAccountId,
      amount,
      description,
      type,
    } = await request.json();

    const companyAccount = await db.companyAccounts.get(companyAccountId);

    if (!companyAccount) {
      await delay(500);
      return new HttpResponse(
        JSON.stringify({ companyAccountId: ['Not found'] }),
        { status: 400 },
      );
    }

    if (Number(companyAccount.balance!) < Number(amount)) {
      await delay(500);
      return new HttpResponse(
        JSON.stringify({ amount: ['Not enough money'] }),
        { status: 400 },
      );
    }

    const counterpartyPaymentAccount = await db.counterpartyPaymentAccounts.get(
      counterpartyPaymentAccountId,
    );

    if (!counterpartyPaymentAccount) {
      await delay(500);
      return new HttpResponse(
        JSON.stringify({ counterpartyPaymentAccountId: ['Required field'] }),
        { status: 400 },
      );
    }

    const transactionType =
      type === CompanyProviderOutcomePaymentMethod.OutboundAch
        ? TransactionType.OutboundAch
        : TransactionType.OutboundWire;

    await db.companyAccounts.update(companyAccountId, {
      balance: Number(companyAccount.balance!) - Number(amount),
    });

    await db.transactions.add({
      type: transactionType,
      status: TransactionStatus.Settled,
      amount: `-${amount}`,
      currency: 'USD',
      companyAccount,
      merchant: '',
      description,
      authorizedAt: new Date(),
    });

    await delay(500);
    return HttpResponse.json({
      message: 'Send funds completed',
    });
  },
);
