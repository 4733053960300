import { faker } from '@faker-js/faker';

import { getRandomItemFromArray } from '@/mocks';
import { AllSeedsData } from '@/seeds';
import {
  CounterpartyBusinessType,
  CounterpartyType,
} from './counterparties.enum';
import type { Counterparty } from './counterparties.interface';

export interface CounterpartySeedDto extends Omit<Counterparty, 'id'> {
  id?: number;
}

export const genInvoicePrefix = () => {
  const symbol = () => faker.helpers.arrayElement(['A', 'B', 'C', 'D']);

  return `${symbol()}${symbol()}${symbol()}`;
};

export const genCounterpartyTypes = (option: number): CounterpartyType[] => {
  const options = {
    0: [],
    1: [CounterpartyType.Customer],
    2: [CounterpartyType.Vendor],
    3: [CounterpartyType.Customer, CounterpartyType.Vendor],
  };
  return options[option];
};

export function genCounterparties(): CounterpartySeedDto[] {
  const counterparties: Counterparty[] = [];
  const counterpartiesNames: Counterparty['name'][] = [];

  AllSeedsData.budgets.forEach(item => {
    item.counterparties.forEach(name => {
      if (counterpartiesNames.includes(name)) return;
      counterpartiesNames.push(name);
    });
  });

  counterpartiesNames.forEach((name, i) => {
    const amountSign = faker.number.int({ min: 1, max: 2 }) % 2 ? '-' : '+';

    counterparties.push({
      id: i + 1,
      name,
      logo: undefined,
      businessType: getRandomItemFromArray(
        Object.values(CounterpartyBusinessType),
      ) as CounterpartyBusinessType,
      country: 'US',
      email: faker.internet.email(),
      invoicePrefix: genInvoicePrefix(),
      spend: `${amountSign}${faker.commerce.price({ min: 100, max: 20000 })}`,
      transactionsSum: `${amountSign}${faker.commerce.price({
        min: 100,
        max: 20000,
      })}`,
      currency: 'USD',
      lastTransactionDate: new Date(),
      types: genCounterpartyTypes(faker.number.int({ min: 0, max: 3 })),
    });
  });

  return counterparties;
}
