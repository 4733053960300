import { faker } from '@faker-js/faker';

import { genLimits, getRandomItemFromArray } from '@/mocks';
import { AllSeedsData } from '@/seeds';

import { CardLimitType, CardStatus, CardType, Currency } from '@/enums';
import type {
  BudgetUser,
  Card,
  CardCredentials,
  CardHolder,
  CardLimit,
  Company,
  CoreUser,
  CoreUserMe,
} from '@/interfaces';

export interface CardMockModel extends Omit<Card, 'budget' | 'companyAccount'> {
  budgetId: number;
  companyAccountId: number;
}

function genPanLast4(i: number): string {
  return String(i * 1834).slice(-4);
}

function getCardHolder(users: CoreUser[], id?: CoreUser['id']): CardHolder {
  const randomUserIndex = faker.number.int({
    min: 0,
    max: users.length - 1,
  });
  const randomUser =
    users.find(user => user.id === id) || users[randomUserIndex];

  return {
    id: randomUser.id,
    firstName: randomUser.firstName,
    lastName: randomUser.lastName,
    avatar: randomUser.avatar,
    fullName: randomUser.fullName,
    status: randomUser.status,
    auth0Id: 'auth0Id',
  };
}

export function genCards(
  budgetUsers: BudgetUser[],
  userMe: CoreUserMe,
  company: Company,
): CardMockModel[] {
  const cards: CardMockModel[] = [];
  const { budgets } = AllSeedsData;

  let cardIndex = 1;

  budgets.forEach((item, budgetIndex) => {
    item.counterparties.forEach(cardName => {
      const cardHolder = getCardHolder(
        budgetUsers
          .filter(budgetUser => budgetUser.budget.name === item.name)
          .map(budgetUser => budgetUser.user),
      );

      const status =
        Math.floor(Math.random() * 10) > 3
          ? CardStatus.Active
          : (getRandomItemFromArray(Object.values(CardStatus)) as CardStatus);

      const permissions = {
        read: true,
        credentials:
          cardHolder.id === userMe.id && status === CardStatus.Active,
        update: status !== CardStatus.Closed,
        destroy: true,
      };

      cards.push({
        id: cardIndex,
        name: cardName,
        panLast4: genPanLast4(cardIndex),
        type: CardType.Virtual,
        cardHolder: company.name.toUpperCase(),
        limits: genLimits(Object.values(CardLimitType)) as CardLimit[],
        status,
        spend: faker.commerce.price({ min: 100, max: 2000 }),
        currency: Currency.Usd,
        billingAddress: faker.location.street(),
        createdAt: new Date(),
        user: cardHolder,
        budgetId: budgetIndex + 1,
        companyAccountId: 1,
        permissions,
        providerId: 1,
      });

      cardIndex++;
    });
  });

  return cards;
}

export function genCardCredentials(count: number): CardCredentials[] {
  const cardCredentials = [];

  for (let i = 1; i <= count; i += 1) {
    cardCredentials.push({
      id: i,
      pan: `${faker.finance.creditCardNumber('############')}${genPanLast4(i)}`,
      cvc: faker.finance.creditCardCVV(),
      expiration: '06 / 26',
    });
  }

  return cardCredentials;
}
