/**
 * @see https://mswjs.io/docs/
 * @see https://dexie.org/docs/
 * @see https://fakerjs.dev/guide/
 */

import { setupWorker } from 'msw/browser';

// MODULES
import {
  readCoreAppVersion,
  readCoreExchangeRate,
  readCoreUser,
  updateCoreUiSettings,
  updateCoreUser,
} from '@/modules/core/core.mock';

import {
  createCompany,
  createCompanyUserInvite,
  createCompanyUserReinvite,
  deleteCompanyUser,
  readCompanyPlan,
  readCompanyUserById,
  readCompanyUsers,
  readCompanyUsersTotal,
  updateCompany,
  updateCompanyUser,
} from '@/modules/companies/companies.mock';

import {
  createCompanyAccount,
  readCompanyAccountById,
  readCompanyAccounts,
  readCompanyAccountsBalancesAvailable,
  readCompanyAccountsBalancesPending,
  updateCompanyAccount,
} from '@/modules/company-accounts/companyAccounts.mock';

import {
  createBudget,
  createBudgetUser,
  deleteBudgetUser,
  readBudgetById,
  readBudgetUser,
  readBudgetUsers,
  readBudgetUsersTotal,
  readBudgets,
  readBudgetsTotal,
  updateBudget,
  updateBudgetUser,
} from '@/modules/budgets/budgets.mock';

import {
  createCard,
  readCardById,
  readCardCredentials,
  readCards,
  readCardsTotal,
  updateCard,
} from '@/modules/cards/cards.mock';

import {
  createTransactionInternal,
  createTransactionPayment,
  readTransactionFee,
  readTransactions,
  readTransactionsReport,
  readTransactionsTotal,
} from '@/modules/transactions/transactions.mock';

import {
  createCounterparty,
  createCounterpartyPaymentAccount,
  readCounterparties,
  readCounterpartyById,
  readCounterpartyPaymentAccounts,
  updateCounterparty,
  updateCounterpartyPaymentAccount,
} from '@/modules/counterparties/counterparties.mock';

import {
  createInvoice,
  createInvoiceItem,
  deleteInvoiceItem,
  readInvoiceById,
  readInvoiceItemById,
  readInvoiceItems,
  readInvoices,
  updateInvoice,
  updateInvoiceItem,
} from '@/modules/invoices/invoices.mock';

import {
  readOverviewStats,
  readOverviewTopIncome,
  readOverviewTopSpend,
  readOverviewTopStatuses,
  readOverviewTotals,
} from '@/modules/overview/overview.mock';

/**
 * !!!! ВАЖНО !!!!
 * При прокидывании хендлеров в воркер нужно учитывать порядок
 * Если в хендлерах одного модуля встречается как запрос по id,
 * так и запрос за чем-то другим, то запрос по id должен идти после всего
 * Например:
 * readBudgetById и readBudgetTotal
 * Урлы у них следующие '.../budget/:id/' и '.../budget/total/'
 * Чтобы при запросе за тоталом мы не провалились в запрос по айди,
 * нужно прописывать хендлеры в правильном порядке:
 * readBudgetTotal,
 * readBudgetById
 */
export default setupWorker(
  // Core
  readCoreAppVersion,
  readCoreExchangeRate,
  readCoreUser,
  updateCoreUser,
  updateCoreUiSettings,

  // CompanyAccounts
  createCompanyAccount,
  readCompanyAccounts,
  readCompanyAccountsBalancesAvailable,
  readCompanyAccountsBalancesPending,
  readCompanyAccountById,
  updateCompanyAccount,

  // Budgets
  createBudget,
  readBudgetUsersTotal,
  createBudgetUser,
  readBudgetsTotal,
  readBudgetById,
  readBudgets,
  readBudgetUser,
  readBudgetUsers,
  updateBudget,
  updateBudgetUser,
  deleteBudgetUser,

  // Users
  readCoreUser,
  updateCoreUser,

  // Cards
  createCard,
  readCardsTotal,
  readCardById,
  readCardCredentials,
  readCards,
  updateCard,

  // Transactions
  createTransactionInternal,
  createTransactionPayment,
  readTransactionFee,
  readTransactions,
  readTransactionsReport,
  readTransactionsTotal,

  // Companies
  createCompany,
  createCompanyUserInvite,
  createCompanyUserReinvite,
  readCompanyPlan,
  readCompanyUsersTotal,
  readCompanyUsers,
  readCompanyUserById,
  updateCompany,
  updateCompanyUser,
  deleteCompanyUser,

  // Counterparties
  createCounterparty,
  readCounterpartyById,
  readCounterparties,
  updateCounterparty,
  createCounterpartyPaymentAccount,
  readCounterpartyPaymentAccounts,
  updateCounterpartyPaymentAccount,

  // Invoices
  createInvoice,
  createInvoiceItem,
  readInvoices,
  readInvoiceItems,
  readInvoiceById,
  readInvoiceItemById,
  updateInvoice,
  updateInvoiceItem,
  deleteInvoiceItem,

  // Overview
  readOverviewStats,
  readOverviewTotals,
  readOverviewTopIncome,
  readOverviewTopStatuses,
  readOverviewTopSpend,
);

export * from './db';
export * from './helpers';
