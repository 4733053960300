import { HttpResponse, delay, http } from 'msw';
import { faker } from '@faker-js/faker';

import { convertStringToArray, db } from '@/mocks';
import { useEnv } from '@/composables';
import { type CounterpartySeedDto, genInvoicePrefix } from '@/seeds';

import { Currency } from '@/enums';
import type { CounterpartyPaymentAccountData } from '@/interfaces';

const { apiBaseUrl } = useEnv();

const baseUrl = `${apiBaseUrl}/api/v1/app`;

export const readCounterparties = http.get(
  `${baseUrl}/core/counterparty/`,
  async ({ request }) => {
    const url = new URL(request.url);
    const limit = Number(url.searchParams.get('limit') || 25);
    const offset = Number(url.searchParams.get('offset') || 0);
    const search = url.searchParams.get('search') || '';
    const includedIds = convertStringToArray('included_ids', url);
    const types = convertStringToArray('types', url, false);

    const filterByType = (counterparty: CounterpartySeedDto) => {
      if (!types.length) return true;
      return Boolean(counterparty.types?.some(type => types.includes(type)));
    };

    const filterByIncludedIds = (counterparty: CounterpartySeedDto) => {
      if (!includedIds.length || offset > 0) return true;

      return includedIds
        .map(id => Number(id))
        .includes(Number(counterparty.id));
    };

    const filterByIncludedIdsExcept = (counterparty: CounterpartySeedDto) => {
      if (!includedIds.length || offset > 0) return true;

      return !includedIds
        .map(id => Number(id))
        .includes(Number(counterparty.id));
    };

    const count = await db.counterparties
      .where('name')
      .startsWithIgnoreCase(search)
      .filter(filterByType)
      .count();

    const includedCounterparties = includedIds.length
      ? await db.counterparties.filter(filterByIncludedIds).toArray()
      : [];

    const otherCounterparties = await db.counterparties
      .where('name')
      .startsWithIgnoreCase(search)
      .filter(filterByIncludedIdsExcept)
      .filter(filterByType)
      .offset(offset)
      .limit(limit)
      .reverse()
      .sortBy('id');

    const results = [...includedCounterparties, ...otherCounterparties];

    await delay(300);
    return HttpResponse.json({
      results,
      count,
    });
  },
);

export const readCounterpartyById = http.get(
  `${baseUrl}/core/counterparty/:id/`,
  async ({ params }) => {
    const counterpartyById = await db.counterparties
      .where('id')
      .equals(Number(params.id))
      .first();

    await delay(500);
    return HttpResponse.json(counterpartyById);
  },
);

export const createCounterparty = http.post(
  `${baseUrl}/core/counterparty/`,
  async ({ request }) => {
    const body = (await request.json()) as object;
    const { name, email, country, businessType } = camelizeKeys(body);

    const newCounterpartyId = await db.counterparties.add({
      name,
      logo: faker.image.avatar(),
      email,
      country,
      businessType,
      invoicePrefix: genInvoicePrefix(),
      amount: '0',
      currency: Currency.Usd,
      lastTransactionDate: new Date(),
      types: [],
    });

    const newCounterparty = await db.counterparties
      .where('id')
      .equals(newCounterpartyId)
      .first();

    await delay(500);
    return HttpResponse.json(newCounterparty);
  },
);

export const updateCounterparty = http.patch(
  `${baseUrl}/core/counterparty/:id/`,
  async ({ request, params }) => {
    const body = (await request.json()) as object;
    const { name, email, country, businessType } = camelizeKeys(body);
    const id = Number(params.id);

    await db.counterparties.update(id, {
      ...(name && { name }),
      ...(country && { country }),
      ...(businessType && { businessType }),
      email: email || '',
    });
    const updatedCounterparty = await db.counterparties
      .where('id')
      .equals(id)
      .first();

    await delay(500);
    return HttpResponse.json(updatedCounterparty);
  },
);

export const createCounterpartyPaymentAccount = http.post(
  `${baseUrl}/karta/counterparty_payment_account/`,
  async ({ request }) => {
    const body = (await request.json()) as object;
    const data = camelizeKeys(body);

    const newCounterpartyPaymentAccountId =
      await db.counterpartyPaymentAccounts.add({
        counterpartyId: data.counterpartyId,
        legalName: data.legalName,
        routingNumber: data.routingNumber,
        accountNumber: data.accountNumber,
        ...(data.country && { country: data.country }),
        ...(data.addressLine1 && { addressLine1: data.addressLine1 }),
        ...(data.addressLine2 && { addressLine2: data.addressLine2 }),
        ...(data.state && { state: data.state }),
        ...(data.city && { city: data.city }),
        ...(data.zipCode && { zipCode: data.zipCode }),
      });

    const newCounterpartyPaymentAccount = await db.counterpartyPaymentAccounts
      .where('id')
      .equals(newCounterpartyPaymentAccountId)
      .first();

    await delay(500);
    return HttpResponse.json(newCounterpartyPaymentAccount);
  },
);

export const readCounterpartyPaymentAccounts = http.get(
  `${baseUrl}/karta/counterparty_payment_account/`,
  async ({ request }) => {
    const url = new URL(request.url);
    const counterpartyIds = convertStringToArray('counterparty_ids', url);
    const limit = Number(url.searchParams.get('limit') || 25);
    const offset = Number(url.searchParams.get('offset') || 0);
    const search = url.searchParams.get('search') || '';
    const includedIds = convertStringToArray('included_ids', url);

    const filterByIncludedIds = (
      counterpartyPaymentAccount: CounterpartyPaymentAccountData,
    ) => {
      if (!includedIds.length || offset > 0) return true;

      return includedIds
        .map(id => Number(id))
        .includes(Number(counterpartyPaymentAccount.id));
    };

    const filterByIncludedIdsExcept = (
      counterpartyPaymentAccount: CounterpartyPaymentAccountData,
    ) => {
      if (!includedIds.length || offset > 0) return true;

      return !includedIds
        .map(id => Number(id))
        .includes(Number(counterpartyPaymentAccount.id));
    };

    const filterByCounterpartyId = (
      counterpartyPaymentAccount: CounterpartyPaymentAccountData,
    ) => {
      if (!counterpartyIds?.length) return true;

      return counterpartyIds.includes(
        Number(counterpartyPaymentAccount.counterpartyId),
      );
    };

    const count = await db.counterpartyPaymentAccounts
      .where('legalName')
      .startsWithIgnoreCase(search)
      .filter(filterByCounterpartyId)
      .count();

    const includedCounterpartyPaymentAccounts = includedIds.length
      ? await db.counterpartyPaymentAccounts
          .where('legalName')
          .startsWithIgnoreCase(search)
          .filter(filterByCounterpartyId)
          .filter(filterByIncludedIds)
          .toArray()
      : [];

    const otherCounterpartyPaymentAccounts =
      await db.counterpartyPaymentAccounts
        .where('legalName')
        .startsWithIgnoreCase(search)
        .filter(filterByCounterpartyId)
        .filter(filterByIncludedIdsExcept)
        .offset(offset)
        .limit(limit)
        .reverse()
        .sortBy('id');

    const results = [
      ...includedCounterpartyPaymentAccounts,
      ...otherCounterpartyPaymentAccounts,
    ];

    await delay(300);
    return HttpResponse.json({
      results,
      count,
    });
  },
);

export const updateCounterpartyPaymentAccount = http.patch(
  `${baseUrl}/karta/counterparty_payment_account/:id/`,
  async ({ request, params }) => {
    const body = (await request.json()) as object;
    const data = camelizeKeys(body);
    const id = Number(params.id);

    await db.counterpartyPaymentAccounts.update(id, {
      ...(data.legalName && { legalName: data.legalName }),
      ...(data.routingNumber && { routingNumber: data.routingNumber }),
      ...(data.accountNumber && { accountNumber: data.accountNumber }),
      ...(data.country && { country: data.country }),
      ...(data.addressLine1 && { addressLine1: data.addressLine1 }),
      ...(data.addressLine2 && { addressLine2: data.addressLine2 }),
      ...(data.state && { state: data.state }),
      ...(data.city && { city: data.city }),
      ...(data.zipCode && { zipCode: data.zipCode }),
    });
    const updatedCounterpartyPaymentAccount =
      await db.counterpartyPaymentAccounts.where('id').equals(id).first();

    await delay(500);
    return HttpResponse.json(updatedCounterpartyPaymentAccount);
  },
);
