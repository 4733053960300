import { DATE_PICKER_FORMAT_DEFAULT } from '@/data';
import { Currency } from '@/enums';

export interface OverviewSeedDto {
  date: string;
  value: string;
  currency: Currency;
  isConverted: boolean;
}

export function genOverviewStats() {
  const dayjs = useDayjs();

  const endDate = dayjs();
  const startDate = dayjs().subtract(1, 'year');

  let current = dayjs(startDate);

  const data: OverviewSeedDto[] = [];

  while (!current.isAfter(dayjs(endDate))) {
    const currentValue = Math.floor(Math.random() * 100000) + 1;

    data.push({
      date: current.format(DATE_PICKER_FORMAT_DEFAULT),
      value: String(currentValue.toFixed(2)),
      currency: Currency.Usd,
      isConverted: false,
    });

    current = current.add(1, 'days');
  }

  return data;
}
