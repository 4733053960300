import { HttpResponse, delay, http } from 'msw';

import { useEnv } from '@/composables';
import { db } from '@/mocks';
import pkg from '../../../package.json';

const { apiBaseUrl } = useEnv();

const baseUrl = `${apiBaseUrl}/api/v1/app`;

window.DEMO_VERSION = pkg.version;

const version = () => window.DEMO_VERSION;

export const readCoreAppVersion = http.get(
  `${baseUrl}/core/version/`,
  async () => {
    await delay(500);
    return HttpResponse.json({ version: version() });
  },
);

export const readCoreExchangeRate = http.get(
  `${baseUrl}/karta/currency_exchange_rate/`,
  async () => {
    const result = [
      {
        fromCurrency: 'EUR',
        toCurrency: 'USD',
        value: 0.98765,
      },
      {
        fromCurrency: 'GBP',
        toCurrency: 'USD',
        value: 0.75,
      },
      {
        fromCurrency: 'IDR',
        toCurrency: 'USD',
        value: 15519.85,
      },
    ];

    await delay(500);
    return HttpResponse.json(result);
  },
);

export const updateCoreUser = http.patch(
  `${baseUrl}/core/user/`,
  async ({ request }) => {
    const {
      first_name: firstName,
      last_name: lastName,
      phone,
    } = await request.json();

    const userMe = await db.userMe.toCollection().first();

    await db.userMe.update(userMe!.id!, {
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
      ...(phone && { phone }),
    });
    const updatedUser = await db.userMe.toCollection().first();

    await delay(500);
    return HttpResponse.json(updatedUser);
  },
);

export const readCoreUser = http.get(`${baseUrl}/core/user/`, async () => {
  const userMe = await db.userMe.toCollection().first();

  await delay(500);
  return HttpResponse.json(userMe);
});

export const updateCoreUiSettings = http.put(
  `${baseUrl}/core/ui_settings/`,
  async ({ request }) => {
    const reqAsJson = await request.json();

    await delay(500);
    return HttpResponse.json(reqAsJson);
  },
);
