import { faker } from '@faker-js/faker';

import {
  TransactionChargeType,
  TransactionStatus,
  TransactionType,
} from '@/enums';
import { getRandomItemFromArray } from '@/mocks';

import { AllSeedsData, type CardMockModel } from '@/seeds';
import type {
  Budget,
  CompanyAccount,
  Counterparty,
  Transaction,
  TransactionCounterparty,
} from '@/interfaces';

export function genTransactions(
  count: number,
  cards: CardMockModel[],
  budgets: Budget[],
  companyAccount: CompanyAccount,
  counterparties?: Counterparty[],
): Transaction[] {
  const transactions: Transaction[] = [];

  for (let i = 1; i <= count; i += 1) {
    const budget =
      budgets[faker.number.int({ min: 0, max: budgets.length - 1 })];
    const currentBudgetCards = cards.filter(c => c.budgetId === budget.id);

    const randomCard = currentBudgetCards.length
      ? currentBudgetCards[
          faker.number.int({ min: 0, max: currentBudgetCards.length - 1 })
        ]
      : undefined;

    let type = getRandomItemFromArray<TransactionType>(
      Object.values(TransactionType).filter(
        item =>
          ![
            TransactionType.InternalTransfer,
            TransactionType.InternationalTransactionAttemptCharge,
          ].includes(item),
      ),
    );

    const isCardTransaction = () =>
      [
        TransactionType.CardPayment,
        TransactionType.CardRefund,
        TransactionType.KartaCharge,
      ].includes(type);

    if (
      isCardTransaction() &&
      !randomCard &&
      type !== TransactionType.KartaCharge
    ) {
      type = TransactionType.InboundWire;
    }

    const amountSign = [
      TransactionType.CardRefund,
      TransactionType.InboundAch,
      TransactionType.InboundWire,
      TransactionType.InboundKarta,
      TransactionType.KartaCharge,
      TransactionType.InboundCrypto,
    ].includes(type)
      ? '+'
      : '-';

    const isAchOrWire = () =>
      [
        TransactionType.InboundAch,
        TransactionType.OutboundAch,
        TransactionType.InboundWire,
        TransactionType.OutboundWire,
      ].includes(type);

    let counterparty: Partial<TransactionCounterparty> | undefined;
    const counterpartiesCount = counterparties?.length;

    if (isAchOrWire() && counterpartiesCount) {
      const randomCounterparty =
        counterparties[
          Math.floor(Math.random() * (counterpartiesCount - 1) + 1)
        ];

      counterparty = {
        id: randomCounterparty.id,
      };
    }

    let status =
      amountSign === '+'
        ? (getRandomItemFromArray(
            Object.values(TransactionStatus),
          ) as TransactionStatus)
        : (getRandomItemFromArray([
            TransactionStatus.Declined,
            TransactionStatus.Settled,
          ]) as TransactionStatus);

    const hasCharge = type === TransactionType.KartaCharge;
    const hasFee = faker.datatype.boolean() && !hasCharge;

    const amount =
      type === TransactionType.KartaCharge
        ? '-0.10'
        : `${amountSign}${faker.commerce.price({ min: 100, max: 20000 })}`;
    const fee = hasFee ? faker.commerce.price({ min: 0, max: 100 }) : 0;
    const totalAmountNumber = Number(amount) - Number(fee);
    let totalAmount = totalAmountNumber.toFixed(2);

    if (totalAmountNumber >= 0) {
      totalAmount = `+${totalAmount}`;
    }

    if (hasCharge) {
      status = TransactionStatus.Settled;
    }

    const counterpartiesNames: Counterparty['name'][] = [];

    AllSeedsData.budgets[budget.id - 1]?.counterparties.forEach(name => {
      if (counterpartiesNames.includes(name)) return;
      counterpartiesNames.push(name);
    });

    const counterpartiesName = getRandomItemFromArray(counterpartiesNames);

    transactions.push({
      id: i,
      type,
      status,
      chargeType: TransactionChargeType.CardsIssuing,
      amount,
      totalAmount,
      hasFee,
      companyAccount,
      budget: isCardTransaction() ? budget : undefined,
      card: isCardTransaction() ? randomCard : undefined,
      user: isCardTransaction() ? randomCard?.user : undefined,
      currency: 'USD',
      mcc: 'mcc',
      merchant: counterpartiesName,
      merchantRaw: `${counterpartiesName} ${faker.finance.bic()}`,
      description: faker.finance.transactionDescription(),
      providerDeclineReason: 'Transaction declined by some reason',
      declineReason: 'Transaction declined by some reason',
      authorizedAt: new Date(),
      counterparty,
    });
  }

  return transactions;
}
