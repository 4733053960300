export enum InvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  Void = 'VOID',
}

export enum InvoiceCreateStep {
  Edit = 'EDIT',
  Items = 'ITEMS',
  Review = 'REVIEW',
}
