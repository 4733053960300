import { faker } from '@faker-js/faker';

import { getRandomItemFromArray } from '@/mocks';
import { AllSeedsData } from '@/seeds';
import {
  CompanyAccountStatus,
  CompanyAccountType,
} from './companyAccounts.enum';
import type { CompanyAccount } from './companyAccounts.interface';

export const genCompanyAccounts = (): CompanyAccount[] => {
  const companyAccounts: CompanyAccount[] = [];
  const companyPrimaryAccountName = getRandomItemFromArray(
    AllSeedsData.primaryCompanyNames,
  );

  companyAccounts.push({
    id: 1,
    name: companyPrimaryAccountName,
    accountNumber: `USD47000${faker.finance.accountNumber(4)}`,
    kartaId: `USD610000${faker.finance.accountNumber(4)}`,
    currency: 'USD',
    balance: faker.commerce.price({ min: 10000, max: 2000000 }),
    spendThisMonth: faker.commerce.price({ min: 10, max: 4000 }),
    type: CompanyAccountType.Primary,
    routingNumberAch: faker.finance.accountNumber(8),
    routingNumberWire: faker.finance.accountNumber(10),
    beneficiaryName: faker.company.name(),
    beneficiaryAddress: faker.location.streetAddress(true),
    bankName: faker.company.name(),
    bankAddress: faker.location.streetAddress(true),
    wireInternationalDetails: {
      accountNumber: faker.finance.accountNumber(8),
      bankName: faker.company.name(),
      swiftCode: faker.finance.bic(),
    },
    providerId: 1,
    daysBeforeMoneyOver: faker.number.int({ min: 0, max: 60 }),
    cardIssuingReason: null,
    permissions: {
      read: true,
      balances: true,
      update: true,
      destroy: false,
    },
    status: CompanyAccountStatus.Active,
  });

  AllSeedsData.subAccountsNames.forEach((name, i) => {
    companyAccounts.push({
      id: i + 2,
      name,
      accountNumber: undefined,
      kartaId: `USD610000${faker.finance.accountNumber(4)}`,
      currency: 'USD',
      balance: '0',
      spendThisMonth: '0',
      type: CompanyAccountType.SubAccount,
      routingNumberAch: undefined,
      routingNumberWire: undefined,
      beneficiaryName: undefined,
      beneficiaryAddress: undefined,
      bankName: undefined,
      bankAddress: undefined,
      providerId: 1,
      wireInternationalDetails: undefined,
      sourceAccount: {
        id: 1,
        name: companyPrimaryAccountName,
        type: CompanyAccountType.Primary,
      },
      daysBeforeMoneyOver: faker.number.int({ min: 0, max: 60 }),
      cardIssuingReason: null,
      permissions: {
        read: true,
        balances: true,
        update: true,
        destroy: false,
      },
      status: CompanyAccountStatus.Active,
    });
  });

  return companyAccounts;
};
