import { HttpResponse, delay, http } from 'msw';
import { faker } from '@faker-js/faker';
import { omit } from 'lodash-es';

import { convertStringToArray, db } from '@/mocks';

import { useEnv } from '@/composables';
import {
  CompanyAccountType,
  Currency,
  TransactionStatus,
  TransactionType,
} from '@/enums';
import type { CardHolder, CompanyAccount } from '@/interfaces';

const { apiBaseUrl } = useEnv();

const baseUrl = `${apiBaseUrl}/api/v1/app`;

export const readCompanyAccounts = http.get(
  `${baseUrl}/karta/company_account/`,
  async ({ request }) => {
    const url = new URL(request.url);
    const search = url.searchParams.get('search') || '';
    const types = convertStringToArray('types', url, false);
    const excludedIds = convertStringToArray('excluded_ids', url);
    const ids = convertStringToArray('ids', url);

    const filterByExcludedIds = (account: CompanyAccount) => {
      if (!excludedIds.length) return true;
      return !excludedIds.map(id => Number(id)).includes(account.id);
    };

    const filterByType = (account: CompanyAccount) => {
      if (!types.length) return true;
      return types.includes(account.type);
    };

    const results = !ids.length
      ? await db.companyAccounts
          .where('name')
          .startsWithIgnoreCase(search)
          .filter(filterByExcludedIds)
          .filter(filterByType)
          .sortBy('id')
      : await db.companyAccounts
          .where('id')
          .anyOf(ids)
          .filter(filterByType)
          .toArray();

    const count = !ids.length
      ? await db.companyAccounts
          .where('name')
          .startsWithIgnoreCase(search)
          .filter(filterByExcludedIds)
          .filter(filterByType)
          .count()
      : results.length;

    await delay(500);
    return HttpResponse.json({ results, count });
  },
);

export const readCompanyAccountsBalancesAvailable = http.get(
  `${baseUrl}/karta/company_account/balance_available/`,
  async () => {
    const companyAccounts = await db.companyAccounts.toArray();
    const kartaBalance = companyAccounts.reduce((accum, current) => {
      const currentBalance = Number(current.balance);

      const sum = Number(accum) + currentBalance;

      return sum.toFixed(2);
    }, '');

    const results = {
      totalBalance: kartaBalance,
      currency: Currency.Usd,
      balances: [
        {
          currency: Currency.Usd,
          value: kartaBalance,
        },
        {
          currency: Currency.Eur,
          value: 0,
        },
      ],
    };

    await delay(200);
    return HttpResponse.json(results);
  },
);

export const readCompanyAccountsBalancesPending = http.get(
  `${baseUrl}/karta/company_account/balance_pending/`,
  async () => {
    const results = {
      totalBalance: 0,
      currency: Currency.Usd,
      balances: [],
    };

    await delay(200);
    return HttpResponse.json(results);
  },
);

export const readCompanyAccountById = http.get(
  `${baseUrl}/karta/company_account/:id/`,
  async ({ params }) => {
    const companyAccountById = await db.companyAccounts
      .where('id')
      .equals(Number(params.id))
      .first();

    await delay(500);
    return HttpResponse.json(companyAccountById);
  },
);

export const updateCompanyAccount = http.patch(
  `${baseUrl}/karta/company_account/:id/`,
  async ({ request, params }) => {
    const { name, description } = await request.json();

    const id = Number(params.id);

    await db.companyAccounts.update(id, { name, description });
    const updatedCompanyAccount = await db.companyAccounts
      .where('id')
      .equals(id)
      .first();

    await delay(500);
    return HttpResponse.json(updatedCompanyAccount);
  },
);

export const createCompanyAccount = http.post(
  `${baseUrl}/karta/company_account/`,
  async ({ request }) => {
    const {
      name,
      description,
      source_account_id: sourceAccountId,
      deposit_amount: depositAmount,
      currency,
    } = await request.json();

    const userMe = await db.userMe.toCollection().first();
    const user = await db.users
      .where('id')
      .equals(userMe?.id as number)
      .first();

    const cardHolder = omit(user, 'username') as CardHolder;
    const companyAccount = await db.companyAccounts.get(sourceAccountId);
    if (sourceAccountId && depositAmount) {
      if (companyAccount && (companyAccount.balance as any) < depositAmount) {
        await delay(500);
        return new HttpResponse(
          JSON.stringify({ depositAmount: ['Incorrect balance'] }),
          {
            status: 422,
          },
        );
      }

      await db.companyAccounts.update(sourceAccountId, {
        balance: Number(companyAccount!.balance) - depositAmount,
      });

      await db.transactions.add({
        type: TransactionType.InternalTransfer,
        status: TransactionStatus.Settled,
        amount: `-${depositAmount}`,
        totalAmount: `-${depositAmount}`,
        currency,
        companyAccount,
        merchant: '',
        authorizedAt: new Date(),
        user: cardHolder,
      });
    }

    const newAccountId = await db.companyAccounts.add({
      name,
      description,
      currency,
      balance: depositAmount || 0,
      type: CompanyAccountType.SubAccount,
      daysBeforeMoneyOver: faker.number.int({ min: 0, max: 60 }),
      kartaId: `USD610000${faker.finance.accountNumber(4)}`,
      cardIssuingReason: null,
      sourceAccount: {
        id: companyAccount?.id,
        name: companyAccount?.name,
        type: companyAccount?.type,
      },
      permissions: {
        read: true,
        balances: true,
        update: true,
        destroy: false,
      },
    });

    const newAccount = await db.companyAccounts.get(newAccountId);

    await db.transactions.add({
      type: TransactionType.InternalTransfer,
      status: TransactionStatus.Settled,
      amount: depositAmount,
      description: `from Karta ID: USD610000${faker.finance.accountNumber(4)}`,
      totalAmount: depositAmount,
      currency,
      companyAccount: newAccount,
      merchant: '',
      authorizedAt: new Date(),
      user: cardHolder,
    });

    await delay(500);
    return HttpResponse.json(newAccount);
  },
);
