import { createHead } from '@unhead/vue';
import * as Sentry from '@sentry/vue';
import { vMaska } from 'maska';
import { VueQueryPlugin } from '@tanstack/vue-query';

import '@karta.io/ui-components/css/theme-default.css';
import '@karta.io/ui-components/css/main.css';
import '@karta.io/app-components/dist/style.css';

import '@/assets/styles/main.scss';

import { auth0 } from '@/modules/auth/auth.service';
import router from '@/router';
import mockWorker, { db } from '@/mocks';

import { useEnv } from '@/composables';
import { SENTRY_DENY_URLS, SENTRY_IGNORE_ERRORS } from '@/data';
import App from './App.vue';

const { appEnv, appType, appVersion, isProduction, isStaging, sentryDsn } =
  useEnv();

const app = createApp(App);
const head = createHead();

function initSentry() {
  Sentry.init({
    app,
    dsn: sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    environment: appEnv,
    release: `karta.${appType}@${appVersion}`,
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    /**
     * Исключение ошибок и ненужных url
     * @see https://gist.github.com/impressiver/5092952
     * https://docs.sentry.io/platforms/javascript/configuration/filtering/
     */
    ignoreErrors: SENTRY_IGNORE_ERRORS,
    denyUrls: SENTRY_DENY_URLS,
  });
}

async function initDemo() {
  await db.init();

  app.use(createPinia());
  app.use(router);
  app.use(auth0);
  app.use(head);
  app.use(VueQueryPlugin, {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: 1,
        },
      },
    },
  });
  app.directive('maska', vMaska);
  app.mount('#app');
}

initDemo();
mockWorker.start({ onUnhandledRequest: 'bypass' });

if (isProduction || isStaging) {
  initSentry();
}
