import { HttpResponse, delay, http } from 'msw';
import { faker } from '@faker-js/faker';

import type { Counterparty } from '@/interfaces';
import { db } from '@/mocks';
import { useEnv } from '@/composables';
import type {
  Invoice,
  InvoiceCounterpartyCompany,
  InvoiceItem,
  InvoiceItemDto,
  InvoiceItemEditableData,
} from './invoices.interface';
import { InvoiceStatus } from './invoices.enum';

const { apiBaseUrl } = useEnv();

const baseUrl = `${apiBaseUrl}/api/v1/app`;

const invoiceCounterpartyCompany: InvoiceCounterpartyCompany = {
  id: 1,
  name: 'Karta',
  address:
    '1007 Noth Orange Street 4th Floor, Wilmington, DE 19801, United States',
  phone: '+13022485700',
};

export const readInvoices = http.get(
  `${baseUrl}/core/invoice/`,
  async ({ request }) => {
    const url = new URL(request.url);
    const limit = Number(url.searchParams.get('limit') || 25);
    const offset = Number(url.searchParams.get('offset') || 0);
    /**
     * @todo Обсудить по какому полю будет поиск
     * и будет ли он вообще)
     */
    // const search = req.url.searchParams.get('search') || '';

    const count = await db.invoices.count();

    const results = await db.invoices
      .offset(offset)
      .limit(limit)
      .reverse()
      .sortBy('dueDate');

    await delay(300);
    return HttpResponse.json({ results, count });
  },
);

export const readInvoiceItems = http.get(
  `${baseUrl}/core/invoice_item/`,
  async ({ request }) => {
    const url = new URL(request.url);
    const limit = Number(url.searchParams.get('limit') || 25);
    const offset = Number(url.searchParams.get('offset') || 0);
    const search = url.searchParams.get('search') || '';
    const invoiceId = Number(url.searchParams.get('invoice_id')) || null;

    const filterByInvoiceId = (invoiceItem: InvoiceItemDto) => {
      if (!invoiceId) return true;
      return invoiceItem.invoiceId === invoiceId;
    };

    const count = await db.invoiceItems
      .where('name')
      .startsWithIgnoreCase(search)
      .filter(filterByInvoiceId)
      .count();

    const results = await db.invoiceItems
      .where('name')
      .startsWithIgnoreCase(search)
      .filter(filterByInvoiceId)
      .offset(offset)
      .limit(limit)
      .reverse()
      .sortBy('id');

    await delay(300);
    return HttpResponse.json({ results, count });
  },
);

export const readInvoiceById = http.get(
  `${baseUrl}/core/invoice/:id/`,
  async ({ params }) => {
    const invoiceById = await db.invoices
      .where('id')
      .equals(Number(params.id))
      .first();

    await delay(500);
    return HttpResponse.json(invoiceById);
  },
);

export const readInvoiceItemById = http.get(
  `${baseUrl}/core/invoice_item/:id/`,
  async ({ params }) => {
    const invoiceItemById = await db.invoices
      .where('id')
      .equals(Number(params.id))
      .first();

    await delay(500);
    return HttpResponse.json(invoiceItemById);
  },
);

export const createInvoice = http.post(
  `${baseUrl}/core/invoice/`,
  async ({ request }) => {
    const body = (await request.json()) as object;
    const { counterpartyId, email, dueDate } = camelizeKeys(body);

    const counterparty = (await db.counterparties.get(
      Number(counterpartyId),
    )) as Counterparty;

    const newInvoiceId = await db.invoices.add({
      counterparty,
      email,
      dueDate,
      status: InvoiceStatus.Draft,
      counterpartyCompany: invoiceCounterpartyCompany.id,
      counterpartyCompanyAddress: invoiceCounterpartyCompany.address,
      counterpartyCompanyName: invoiceCounterpartyCompany.name,
      counterpartyCompanyPhone: invoiceCounterpartyCompany.phone,
    });

    const newInvoice = await db.invoices
      .where('id')
      .equals(newInvoiceId)
      .first();

    await delay(500);
    return HttpResponse.json(newInvoice);
  },
);

export const createInvoiceItem = http.post(
  `${baseUrl}/core/invoice_item/`,
  async ({ request }) => {
    const body = (await request.json()) as object;
    const { name, quantity, price, invoiceId } = camelizeKeys(body);

    const newInvoiceItemId = await db.invoiceItems.add({
      name,
      quantity,
      price,
      invoiceId,
    });

    const newInvoiceItem = (await db.invoiceItems
      .where('id')
      .equals(newInvoiceItemId)
      .first()) as InvoiceItem;

    const invoice = await db.invoices.get(Number(invoiceId));
    const invoiceItems = invoice?.invoiceItems || [];
    const newInvoiceItems = [...invoiceItems, newInvoiceItem];
    const totalPrice = String(
      newInvoiceItems.reduce(
        (acc, current) => (acc += Number(current.price) * current.quantity),
        0,
      ),
    );

    await db.invoices.update(Number(invoiceId), {
      items: newInvoiceItems,
      totalPrice,
    });

    await delay(500);
    return HttpResponse.json(newInvoiceItems);
  },
);

export const updateInvoice = http.patch(
  `${baseUrl}/core/invoice/:id/`,
  async ({ request, params }) => {
    const body = (await request.json()) as object;
    const { counterpartyId, email, dueDate, status } = camelizeKeys(body);
    const id = Number(params.id);

    let counterparty = null;
    if (counterpartyId) {
      counterparty = await db.counterparties.get(Number(counterpartyId));
    }

    const invoice = (await db.invoices.get(id)) as Invoice;

    let number = null;
    if (invoice.status !== InvoiceStatus.Draft && !invoice.number) {
      number = faker.number.int({ min: 1, max: 1000 });
    }
    await db.invoices.update(id, {
      ...(counterparty && { counterparty }),
      ...(email && { email }),
      ...(dueDate && { dueDate }),
      ...(status && { status }),
      ...(number && { number }),
    });
    const updatedInvoice = await db.invoices.where('id').equals(id).first();

    await delay(500);
    return HttpResponse.json(updatedInvoice);
  },
);

export const updateInvoiceItem = http.patch(
  `${baseUrl}/core/invoice_item/:id/`,
  async ({ request, params }) => {
    const { name, quantity, price } =
      (await request.json()) as InvoiceItemEditableData;
    const id = Number(params.id);

    await db.invoiceItems.update(id, {
      ...(name && { name }),
      ...(quantity && { quantity }),
      ...(price && { price }),
    });
    const updatedInvoiceItem = (await db.invoiceItems.get(id)) as InvoiceItem;

    if (price) {
      const invoice = await db.invoices.get(
        Number(updatedInvoiceItem.invoiceId),
      );
      const invoiceItems =
        invoice?.invoiceItems?.filter(item => item.id !== id) || [];
      const newInvoiceItems = [...invoiceItems, updatedInvoiceItem];
      const totalPrice = String(
        newInvoiceItems.reduce(
          (acc, current) => (acc += Number(current.price) * current.quantity),
          0,
        ),
      );

      await db.invoices.update(Number(updatedInvoiceItem.invoiceId), {
        items: newInvoiceItems,
        totalPrice,
      });
    }

    await delay(500);
    return HttpResponse.json(updatedInvoiceItem);
  },
);

export const deleteInvoiceItem = http.delete(
  `${baseUrl}/core/invoice_item/:id/`,
  async ({ params }) => {
    const invoiceItemId = Number(params.id);

    const invoiceItem = (await db.invoiceItems.get(
      invoiceItemId,
    )) as InvoiceItem;

    const invoice = await db.invoices.get(Number(invoiceItem.invoiceId));
    const newInvoiceItems =
      invoice?.invoiceItems?.filter(
        invoiceItem => invoiceItem.id !== invoiceItemId,
      ) || [];
    const totalPrice = String(
      newInvoiceItems.reduce(
        (acc, current) => (acc += Number(current.price) * current.quantity),
        0,
      ),
    );

    await db.invoices.update(Number(invoiceItem.invoiceId), {
      items: newInvoiceItems,
      totalPrice,
    });

    await db.invoiceItems.delete(invoiceItemId);

    await delay(500);
    return HttpResponse.json();
  },
);
