import { HttpResponse, delay, http } from 'msw';

import { db } from '@/mocks';
import type { OverviewSeedDto } from '@/seeds';

import { useEnv } from '@/composables';

import { Currency, TransactionStatus, TransactionType } from '@/enums';
import type {
  OverviewStatsItem,
  OverviewTopList,
  OverviewTotalsReadResponse,
  Transaction,
} from '@/interfaces';

const { apiBaseUrl } = useEnv();

const baseUrl = `${apiBaseUrl}/api/v1/app`;

export const readOverviewStats = http.get(
  `${baseUrl}/karta/overview/stats/`,
  async ({ request }) => {
    const dayjs = useDayjs();
    const url = new URL(request.url);
    const startDate = url.searchParams.get('start_date');
    const endDate = url.searchParams.get('end_date');
    const startDateDayjs = dayjs(startDate);
    const endDateDayjs = dayjs(endDate);

    const filterByDate = (item: OverviewSeedDto) => {
      const itemDateDayjs = dayjs(item?.date);

      if (
        (startDateDayjs.isBefore(itemDateDayjs) &&
          endDateDayjs.isAfter(itemDateDayjs)) ||
        startDateDayjs.isSame(itemDateDayjs) ||
        endDateDayjs.isSame(itemDateDayjs)
      ) {
        return true;
      }

      return false;
    };

    const stats = await db.overviewStats.filter(filterByDate).toArray();

    const response: OverviewStatsItem[] = stats.map(item => ({
      trend: '0',
      currentPeriod: {
        ...item,
      },
      previousPeriod: null,
    }));

    await delay(300);

    return HttpResponse.json(response);
  },
);

export const readOverviewTotals = http.get(
  `${baseUrl}/karta/overview/totals/`,
  async () => {
    const response: OverviewTotalsReadResponse = {
      balance: {
        value: String((Math.floor(Math.random() * 100000) + 1).toFixed(2)),
        currency: Currency.Usd,
        isConverted: false,
        trend: '0',
      },
      spend: {
        value: String((Math.floor(Math.random() * 100000) + 1).toFixed(2)),
        currency: Currency.Usd,
        isConverted: false,
        trend: '0',
      },
      income: {
        value: String((Math.floor(Math.random() * 100000) + 1).toFixed(2)),
        currency: Currency.Usd,
        isConverted: false,
        trend: '0',
      },
      summary: {
        value: String((Math.floor(Math.random() * 100000) + 1).toFixed(2)),
        currency: Currency.Usd,
        isConverted: false,
        trend: '0',
      },
    };

    await delay(300);
    return HttpResponse.json(response);
  },
);

export const readOverviewTopIncome = http.get(
  `${baseUrl}/karta/overview/top_income/`,
  async () => {
    const filterByType = (transaction: Transaction) => {
      return (
        [
          TransactionType.InboundAch,
          TransactionType.InboundWire,
          TransactionType.InboundKarta,
          TransactionType.InboundCrypto,
          TransactionType.CardRefund,
        ].includes(transaction.type) &&
        transaction.status === TransactionStatus.Settled
      );
    };
    const transactions = await db.transactions.filter(filterByType).toArray();

    const total = transactions.reduce(
      (accum, current) => accum + Number(current.amount),
      0,
    );

    const merchants: Record<Transaction['merchant'], OverviewTopList> =
      transactions.reduce((accum, current) => {
        const value =
          // @typescript-eslint/ban-ts-comment
          // @ts-expect-error Все работает
          Number(accum?.[current.merchant]?.value || 0) +
          Number(current.amount);
        return {
          ...accum,
          [current.merchant]: {
            name: current.merchant,
            value,
            currency: current.currency,
            percent: ((value / total) * 100).toFixed(2),
            isConverted: false,
          },
        };
      }, {});

    const topMerchantsList = Object.entries(merchants)
      .sort(([, item1], [, item2]) => Number(item2.value) - Number(item1.value))
      .map(([, value]) => value);
    const topFourMerchantsList = topMerchantsList.slice(0, 4);
    const otherMerchants = {
      name: 'Other',
      value: topMerchantsList
        .slice(4, topMerchantsList.length)
        .reduce((accum, current) => accum + Number(current.value), 0)
        .toFixed(2),
      currency: Currency.Usd,
      percent: '0',
      isConverted: false,
    };

    otherMerchants.percent = (
      (Number(otherMerchants.value) / total) *
      100
    ).toFixed(2);

    topFourMerchantsList.push(otherMerchants);

    await delay(300);
    return HttpResponse.json(topFourMerchantsList);
  },
);

export const readOverviewTopStatuses = http.get(
  `${baseUrl}/karta/overview/top_statuses/`,
  async () => {
    const filterByType = (transaction: Transaction) => {
      return [
        TransactionType.KartaCharge,
        TransactionType.InternationalTransactionAttemptCharge,
        TransactionType.OutboundKarta,
        TransactionType.OutboundWire,
        TransactionType.OutboundAch,
        TransactionType.OutboundAdjustment,
        TransactionType.PullAch,
        TransactionType.CardPayment,
      ].includes(transaction.type);
    };
    const transactions = await db.transactions.filter(filterByType).toArray();

    const total = transactions.reduce(
      (accum, current) => accum + Math.abs(Number(current.amount)),
      0,
    );

    const data = {
      [TransactionStatus.Settled]: {
        name: 'Settled',
        value: 0,
        currency: 'USD',
        percent: '0',
        isConverted: false,
      },
      [TransactionStatus.Pending]: {
        name: 'Pending',
        value: 0,
        currency: 'USD',
        percent: '0',
        isConverted: false,
      },
      [TransactionStatus.Declined]: {
        name: 'Declined',
        value: 0,
        currency: 'USD',
        percent: '0',
        isConverted: false,
      },
      [TransactionStatus.Reversed]: {
        name: 'Reversed',
        value: 0,
        currency: 'USD',
        percent: '0',
        isConverted: false,
      },
    };

    transactions.forEach(item => {
      const value = Math.abs(Number(item.amount));
      data[item.status].value += value;
      data[item.status].percent = (
        (data[item.status].value / total) *
        100
      ).toFixed(2);
    });

    const response = Object.values(data).filter(item => Number(item.value) > 0);

    await delay(300);
    return HttpResponse.json(response);
  },
);

export const readOverviewTopSpend = http.get(
  `${baseUrl}/karta/overview/top_spend/`,
  async () => {
    await delay(300);
    return HttpResponse.json([]);
  },
);
