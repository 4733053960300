import { HttpResponse, delay, http } from 'msw';

import { faker } from '@faker-js/faker';
import type {
  Budget,
  BudgetLimit,
  BudgetUser,
  BudgetUserInBudget,
  CompanyAccount,
  CompanyUser,
  CoreUser,
  CoreUserMe,
} from '@/interfaces';
import {
  BudgetLimitType,
  BudgetStatus,
  BudgetUserRole,
  CompanyUserRole,
  Currency,
  TransactionStatus,
} from '@/enums';
import { convertStringToArray, db, genLimits } from '@/mocks';
import { useEnv } from '@/composables';
import type { BudgetSeedDto } from '@/seeds';

const { apiBaseUrl } = useEnv();

const baseUrl = `${apiBaseUrl}/api/v1/app`;

interface BudgetCreateRequest {
  name: string;
  purpose?: string;
  company_account_ids: number;
  limits: BudgetLimit[];
}

const matchBudgetsWithTransactionsPending = (budgets: BudgetSeedDto[]) => {
  return budgets.map(async budget => {
    const transactionsPending = await db.transactions
      .where(['budget.id', 'status'])
      .equals([Number(budget.id), TransactionStatus.Pending])
      .toArray();

    const pending = transactionsPending.reduce(
      (acc, transaction) => acc + Number(transaction.amount),
      0,
    );

    return {
      ...budget,
      pending: String(pending),
    };
  });
};

const matchBudgetUsersWithTransactionsPending = (budgetUsers: BudgetUser[]) => {
  return budgetUsers.map(async budgetUser => {
    const transactionsPending = await db.transactions
      .where(['budget.id', 'user.id', 'status'])
      .equals([
        Number(budgetUser.budget.id),
        Number(budgetUser.user.id),
        TransactionStatus.Pending,
      ])
      .toArray();

    const pending = transactionsPending.reduce(
      (acc, transaction) => acc + Number(transaction.amount),
      0,
    );

    return {
      ...budgetUser,
      pending: String(pending),
    };
  });
};

const matchBudgetsWithBudgetManagers = (budgets: BudgetSeedDto[]) => {
  return budgets.map(async budget => {
    const budgetManagers = await db.budgetUsers
      .where('budget.id')
      .equals(budget?.id as number)
      .filter(budgetUser => {
        if (!budgetUser) return true;

        return budgetUser.role === BudgetUserRole.Manager;
      })
      .toArray();

    const managers = budgetManagers.map(item => ({
      id: item.user.id,
      firstName: item.user.firstName,
      lastName: item.user.lastName,
      fullName: item.user.fullName,
      avatar: item.user.avatar,
    }));

    return {
      ...budget,
      managers,
    };
  });
};

export const createBudget = http.post(
  `${baseUrl}/karta/budget/`,
  async ({ request }) => {
    const {
      name,
      purpose,
      company_account_id: companyAccountId,
      limits,
    } = (await request.json()) as BudgetCreateRequest;

    const userMe = (await db.userMe.toCollection().first()) as CoreUserMe;

    const coreUserMe = (await db.users.get(Number(userMe.id))) as CoreUser;

    const budgetUserMeId = await db.budgetUsers.add({
      user: coreUserMe,
      role: BudgetUserRole.Manager,
      limits: [],
      createdAt: new Date(),
      permissions: {
        read: true,
        update: true,
        updateToManager: false,
        updateToMember: false,
        destroy: true,
      },
    });

    const companyAccount = (await db.companyAccounts.get(
      companyAccountId,
    )) as CompanyAccount;

    const budgetId = await db.budgets.add({
      name,
      purpose: purpose || undefined,
      limits,
      companyAccount,
      managers: [],
      userCount: 1,
      createdAt: new Date(),
      status: BudgetStatus.Active,
      permissions: {
        read: true,
        update: true,
        destroy: true,
        createMember: true,
      },
    });

    const budget = await db.budgets.get(budgetId);

    await db.budgetUsersInBudgets.add({
      budgetId,
      userId: coreUserMe.id,
    });

    await db.budgetUsers.update(budgetUserMeId, {
      budget,
    });

    const companyUser = (await db.companyUsers
      .where('user.id')
      .equals(coreUserMe.id!)
      .first()) as CompanyUser;

    const companyUserBudgetUsers = companyUser?.budgetUsers || [];
    await db.companyUsers.update(Number(companyUser.id), {
      budgetUsers: [...companyUserBudgetUsers, budgetUserMeId],
    });

    await delay(500);
    return HttpResponse.json(budget);
  },
);

export const createBudgetUser = http.post(
  `${baseUrl}/karta/budget/:budgetId/user/`,
  async ({ request, params }) => {
    const { user_id: userId } = (await request.json()) as { user_id: number };
    const budgetId = Number(params.budgetId);

    const budget = (await db.budgets.get(budgetId)) as Budget;
    const companyUser = (await db.companyUsers
      .where('user.id')
      .equals(userId)
      .first()) as CompanyUser;

    const role =
      companyUser.role ===
      (CompanyUserRole.Admin || companyUser.role === CompanyUserRole.Owner)
        ? BudgetUserRole.Admin
        : BudgetUserRole.Member;

    const budgetUserId = await db.budgetUsers.add({
      budget: {
        id: budget.id,
        status: budget.status,
        name: budget.name,
        purpose: budget.purpose,
      },
      user: companyUser?.user as CoreUser,
      role,
      limits: genLimits(Object.values(BudgetLimitType)) as BudgetLimit[],
      createdAt: new Date(),
      spend: faker.commerce.price({ min: 100, max: 20000 }),
      permissions: {
        read: true,
        update: true,
        updateToManager: role !== BudgetUserRole.Admin,
        updateToMember: role !== BudgetUserRole.Admin,
        destroy: true,
      },
    });

    await db.budgetUsersInBudgets.add({
      budgetId,
      userId,
    });

    const budgetUser = await db.budgetUsers.get(budgetUserId);

    const companyUserBudgetUsers = companyUser?.budgetUsers || [];
    await db.companyUsers.update(Number(companyUser.id), {
      budgetUsers: [...companyUserBudgetUsers, budgetUser],
    });

    await db.budgets.update(budgetId, {
      userCount: budget?.userCount ? budget.userCount + 1 : 1,
    });

    await delay(500);
    return HttpResponse.json(budgetUser);
  },
);

export const readBudgets = http.get(
  `${baseUrl}/karta/budget/`,
  async ({ request }) => {
    const url = new URL(request.url);
    const limit = Number(url.searchParams.get('limit') || 25);
    const offset = Number(url.searchParams.get('offset') || 0);
    const search = url.searchParams.get('search') || '';
    const userIds = convertStringToArray('user_ids', url);
    const includedIds = convertStringToArray('included_ids', url);
    const excludedIds = convertStringToArray('excluded_ids', url);
    const statuses = convertStringToArray('statuses', url, false);
    const companyAccountIds = convertStringToArray('company_account_ids', url);
    const extraFields = convertStringToArray('extra_fields', url, false);
    const ids = convertStringToArray('ids', url);

    const filterByUserIds = (budgetUsersInBudget: BudgetUserInBudget) => {
      if (!userIds?.length) return true;

      return userIds.includes(budgetUsersInBudget.userId);
    };

    const budgetUserInBudgets = await db.budgetUsersInBudgets
      .filter(filterByUserIds)
      .toArray();

    const filterByCompanyAccountId = (budget: BudgetSeedDto) => {
      if (!companyAccountIds?.length) return true;
      return companyAccountIds.includes(budget?.companyAccount.id);
    };

    const filterByStatus = (budget: BudgetSeedDto) => {
      if (!statuses.length) return true;
      return statuses.includes(budget.status);
    };

    const filterByIncludedIds = (budget: BudgetSeedDto) => {
      if (!includedIds.length || offset > 0) return true;

      return includedIds.includes(Number(budget.id));
    };

    const filterByIncludedIdsExcept = (budget: BudgetSeedDto) => {
      if (!includedIds.length || offset > 0) return true;

      return !includedIds.includes(Number(budget.id));
    };

    const filterByExcludedIds = (budget: BudgetSeedDto) => {
      if (!excludedIds.length) return true;

      return !excludedIds.includes(Number(budget.id));
    };

    const filterByUser = (budget: BudgetSeedDto) => {
      if (!userIds?.length) return true;

      return budgetUserInBudgets.some(item => item.budgetId === budget.id);
    };

    const count = !ids.length
      ? await db.budgets
          .where('name')
          .startsWithIgnoreCase(search)
          .filter(filterByCompanyAccountId)
          .filter(filterByUser)
          .filter(filterByStatus)
          .filter(filterByExcludedIds)
          .count()
      : undefined;

    const includedBudgets =
      !ids.length && includedIds.length
        ? await db.budgets
            .where('name')
            .startsWithIgnoreCase(search)
            .filter(filterByCompanyAccountId)
            .filter(filterByUser)
            .filter(filterByStatus)
            .filter(filterByExcludedIds)
            .filter(filterByIncludedIds)
            .toArray()
        : [];

    const budgetsByIds = ids.length
      ? await db.budgets
          .where('id')
          .anyOf(ids)
          .filter(filterByCompanyAccountId)
          .filter(filterByUser)
          .filter(filterByStatus)
          .toArray()
      : [];

    const otherBudgets = !ids.length
      ? await db.budgets
          .where('name')
          .startsWithIgnoreCase(search)
          .filter(filterByCompanyAccountId)
          .filter(filterByUser)
          .filter(filterByIncludedIdsExcept)
          .filter(filterByExcludedIds)
          .filter(filterByStatus)
          .offset(offset)
          .limit(limit)
          .reverse()
          .sortBy('createdAt')
      : [];

    let results = await Promise.all(
      matchBudgetsWithBudgetManagers(
        budgetsByIds.length
          ? budgetsByIds
          : [...includedBudgets, ...otherBudgets],
      ),
    );
    let totalPending;

    if (extraFields.includes('pending')) {
      results = await Promise.all(matchBudgetsWithTransactionsPending(results));

      totalPending = results.reduce(
        (acc, budget) => acc + Number(budget.pending),
        0,
      );
    }

    const totalSpend = results.reduce(
      (acc, budget) => acc + Number(budget.spend),
      0,
    );

    await delay(300);
    return HttpResponse.json({
      results,
      count: ids.length ? results.length : count,
      totalSpend,
      ...(totalPending && { totalPending }),
    });
  },
);

export const readBudgetsTotal = http.get(
  `${baseUrl}/karta/budget/total/`,
  async ({ request }) => {
    const url = new URL(request.url);
    const offset = Number(url.searchParams.get('offset') || 0);
    const search = url.searchParams.get('search') || '';
    const userIds = convertStringToArray('user_ids', url);
    const includedIds = convertStringToArray('included_ids', url);
    const excludedIds = convertStringToArray('excluded_ids', url);
    const statuses = convertStringToArray('statuses', url, false);
    const companyAccountIds = convertStringToArray('company_account_ids', url);
    const extraFields = convertStringToArray('extra_fields', url, false);

    const filterByUserIds = (budgetUsersInBudget: BudgetUserInBudget) => {
      if (!userIds?.length) return true;

      return userIds.includes(budgetUsersInBudget.userId);
    };

    const budgetUserInBudgets = await db.budgetUsersInBudgets
      .filter(filterByUserIds)
      .toArray();

    const filterByCompanyAccountId = (budget: BudgetSeedDto) => {
      if (!companyAccountIds?.length) return true;
      return companyAccountIds.includes(budget?.companyAccount.id);
    };

    const filterByStatus = (budget: BudgetSeedDto) => {
      if (!statuses.length) return true;
      return statuses.includes(budget.status);
    };

    const filterByIncludedIds = (budget: BudgetSeedDto) => {
      if (!includedIds.length || offset > 0) return true;

      return includedIds.includes(Number(budget.id));
    };

    const filterByIncludedIdsExcept = (budget: BudgetSeedDto) => {
      if (!includedIds.length || offset > 0) return true;

      return !includedIds.includes(Number(budget.id));
    };

    const filterByExcludedIds = (budget: BudgetSeedDto) => {
      if (!excludedIds.length) return true;

      return !excludedIds.includes(Number(budget.id));
    };

    const filterByUser = (budget: BudgetSeedDto) => {
      if (!userIds?.length) return true;

      return budgetUserInBudgets.some(item => item.budgetId === budget.id);
    };

    const includedBudgets = includedIds.length
      ? await db.budgets
          .where('name')
          .startsWithIgnoreCase(search)
          .filter(filterByCompanyAccountId)
          .filter(filterByUser)
          .filter(filterByStatus)
          .filter(filterByExcludedIds)
          .filter(filterByIncludedIds)
          .toArray()
      : [];

    const otherBudgets = await db.budgets
      .where('name')
      .startsWithIgnoreCase(search)
      .filter(filterByCompanyAccountId)
      .filter(filterByUser)
      .filter(filterByIncludedIdsExcept)
      .filter(filterByExcludedIds)
      .filter(filterByStatus)
      .toArray();

    let matchResults = await Promise.all(
      matchBudgetsWithBudgetManagers([...includedBudgets, ...otherBudgets]),
    );
    let totalPending;

    if (extraFields.includes('pending')) {
      matchResults = await Promise.all(
        matchBudgetsWithTransactionsPending(matchResults),
      );

      totalPending = matchResults.reduce(
        (acc, budget) => acc + Number(budget.pending),
        0,
      );
    }

    const totalSpend = matchResults.reduce(
      (acc, budget) => acc + Number(budget.spend),
      0,
    );

    const result = {
      total: {
        spend: totalSpend.toFixed(2).toString(),
        pending: totalPending?.toFixed(2).toString() || '0',
        currency: Currency.Usd,
      },
      details: [
        {
          spend: totalSpend.toFixed(2).toString(),
          pending: totalPending?.toFixed(2).toString() || '0',
          currency: Currency.Usd,
        },
      ],
    };

    await delay(300);
    return HttpResponse.json(result);
  },
);

export const readBudgetById = http.get(
  `${baseUrl}/karta/budget/:id/`,
  async ({ params }) => {
    const budgetById = await db.budgets
      .where('id')
      .equals(Number(params.id))
      .first();

    if (!budgetById) {
      await delay(500);
      return new HttpResponse(
        JSON.stringify({
          detail: 'Not found.',
        }),
        {
          status: 404,
        },
      );
    }

    const userMe = await db.userMe.toCollection().first();

    const budgetId = Number(budgetById.id);
    const userId = Number(userMe?.id);

    const budgetUser = await db.budgetUsers
      .where(['budget.id', 'user.id'])
      .equals([budgetId, userId])
      .first();

    if (budgetUser) {
      budgetById.budgetUser = {
        limits: budgetUser.limits,
        role: budgetUser.role,
      };
    }

    const results = await Promise.all(
      matchBudgetsWithBudgetManagers([budgetById]),
    );

    await delay(500);
    return HttpResponse.json(results[0]);
  },
);

export const readBudgetUsers = http.get(
  `${baseUrl}/karta/budget/:id/user/`,
  async ({ request, params }) => {
    const url = new URL(request.url);
    const limit = Number(url.searchParams.get('limit') || 25);
    const offset = Number(url.searchParams.get('offset') || 0);
    const search = url.searchParams.get('search') || '';
    const roles = convertStringToArray('roles', url, false);
    const limitTypes = convertStringToArray('limit_types', url, false);
    const budgetId = Number(params.id);
    const includedIds = convertStringToArray('included_ids', url);
    const extraFields = convertStringToArray('extra_fields', url, false);
    const userIds = convertStringToArray('user_ids', url);

    const filterByBudgetId = (budgetUser: BudgetUser) => {
      if (!budgetId) return true;
      return budgetUser?.budget?.id === budgetId;
    };

    const filterByRole = (budgetUser: BudgetUser) => {
      if (!roles.length) return true;

      return roles.includes(budgetUser.role);
    };

    const filterByLimitType = (budgetUser: BudgetUser) => {
      if (!limitTypes.length) return true;

      return limitTypes.includes(BudgetLimitType.NoLimit)
        ? limitTypes.includes(budgetUser.limits?.[0]?.type) ||
            budgetUser.limits.length === 0
        : limitTypes.includes(budgetUser.limits?.[0]?.type);
    };

    const filterByIncludedIds = (budgetUser: BudgetUser) => {
      if (!includedIds.length || offset > 0) return true;

      return includedIds.includes(budgetUser.user.id!);
    };

    const filterByIncludedIdsExcept = (budgetUser: BudgetUser) => {
      if (!includedIds.length || offset > 0) return true;

      return !includedIds.includes(budgetUser.user.id!);
    };

    const count = !userIds.length
      ? await db.budgetUsers
          .where('user.fullName')
          .startsWithIgnoreCase(search)
          .filter(filterByBudgetId)
          .filter(filterByRole)
          .filter(filterByLimitType)
          .count()
      : undefined;

    const includedUsers =
      !userIds.length && includedIds.length
        ? await db.budgetUsers
            .where('user.fullName')
            .startsWithIgnoreCase(search)
            .filter(filterByBudgetId)
            .filter(filterByIncludedIds)
            .filter(filterByRole)
            .filter(filterByLimitType)
            .toArray()
        : [];

    const budgetUsersByIds = userIds.length
      ? await db.budgetUsers
          .where('user.id')
          .anyOf(userIds)
          .filter(filterByBudgetId)
          .offset(offset)
          .limit(limit)
          .toArray()
      : [];

    const otherUsers = !userIds.length
      ? await db.budgetUsers
          .where('user.fullName')
          .startsWithIgnoreCase(search)
          .filter(filterByIncludedIdsExcept)
          .filter(filterByBudgetId)
          .filter(filterByRole)
          .filter(filterByLimitType)
          .offset(offset)
          .limit(limit)
          .reverse()
          .sortBy('createdAt')
      : [];

    let results = [...budgetUsersByIds, ...includedUsers, ...otherUsers];
    let totalPending;

    if (extraFields.includes('pending')) {
      results = await Promise.all(
        matchBudgetUsersWithTransactionsPending(results),
      );

      totalPending = results.reduce(
        (acc, budgetUser) => acc + Number(budgetUser.pending),
        0,
      );
    }

    const totalSpend = results.reduce(
      (acc, budgetUser) => acc + Number(budgetUser?.spend),
      0,
    );

    await delay(500);
    return HttpResponse.json({
      results,
      count: userIds.length ? results.length : count,
      totalSpend,
      ...(totalPending && { totalPending }),
    });
  },
);

export const readBudgetUsersTotal = http.get(
  `${baseUrl}/karta/budget/:id/user/total/`,
  async ({ request, params }) => {
    const url = new URL(request.url);
    const offset = Number(url.searchParams.get('offset') || 0);
    const search = url.searchParams.get('search') || '';
    const roles = convertStringToArray('roles', url, false);
    const limitTypes = convertStringToArray('limit_types', url, false);
    const budgetId = Number(params.id);
    const includedIds = convertStringToArray('included_ids', url);
    const extraFields = convertStringToArray('extra_fields', url, false);

    const filterByBudgetId = (budgetUser: BudgetUser) => {
      if (!budgetId) return true;
      return budgetUser?.budget?.id === budgetId;
    };

    const filterByRole = (budgetUser: BudgetUser) => {
      if (!roles.length) return true;

      return roles.includes(budgetUser.role);
    };

    const filterByLimitType = (budgetUser: BudgetUser) => {
      if (!limitTypes.length) return true;

      return limitTypes.includes(BudgetLimitType.NoLimit)
        ? limitTypes.includes(budgetUser.limits?.[0]?.type) ||
            budgetUser.limits.length === 0
        : limitTypes.includes(budgetUser.limits?.[0]?.type);
    };

    const filterByIncludedIds = (budgetUser: BudgetUser) => {
      if (!includedIds.length || offset > 0) return true;

      return includedIds.includes(budgetUser.user.id!);
    };

    const filterByIncludedIdsExcept = (budgetUser: BudgetUser) => {
      if (!includedIds.length || offset > 0) return true;

      return includedIds.includes(budgetUser.user.id!);
    };

    const includedUsers = includedIds.length
      ? await db.budgetUsers
          .where('user.fullName')
          .startsWithIgnoreCase(search)
          .filter(filterByIncludedIds)
          .toArray()
      : [];

    const otherUsers = await db.budgetUsers
      .where('user.fullName')
      .startsWithIgnoreCase(search)
      .filter(filterByIncludedIdsExcept)
      .filter(filterByBudgetId)
      .filter(filterByRole)
      .filter(filterByLimitType)
      .toArray();

    let allUsers = [...includedUsers, ...otherUsers];
    let totalPending;

    if (extraFields.includes('pending')) {
      allUsers = await Promise.all(
        matchBudgetUsersWithTransactionsPending(allUsers),
      );

      totalPending = allUsers.reduce(
        (acc, budgetUser) => acc + Number(budgetUser.pending),
        0,
      );
    }

    const totalSpend = allUsers.reduce(
      (acc, budgetUser) => acc + Number(budgetUser?.spend),
      0,
    );
    const result = {
      total: {
        spend: totalSpend.toFixed(2).toString(),
        pending: totalPending?.toFixed(2).toString() || '0',
        currency: Currency.Usd,
      },
      details: [
        {
          spend: totalSpend.toFixed(2).toString(),
          pending: totalPending?.toFixed(2).toString() || '0',
          currency: Currency.Usd,
        },
      ],
    };

    await delay(300);
    return HttpResponse.json(result);
  },
);

export const readBudgetUser = http.get(
  `${baseUrl}/karta/budget/:budgetId/user/:userId/`,
  async ({ params }) => {
    const budgetId = Number(params.budgetId);
    const userId = Number(params.userId);

    const budgetUser = await db.budgetUsers
      .where(['budget.id', 'user.id'])
      .equals([budgetId, userId])
      .first();

    if (!budgetUser) {
      await delay(500);
      return new HttpResponse(
        JSON.stringify({
          detail: 'Not found.',
        }),
        {
          status: 404,
        },
      );
    }

    await delay(500);
    return HttpResponse.json(budgetUser);
  },
);

export const updateBudget = http.patch(
  `${baseUrl}/karta/budget/:id/`,
  async ({ request, params }) => {
    const { status, name, purpose, limits } = (await request.json()) as Budget;
    const id = Number(params.id);

    const permissions = {
      read: true,
      update: true,
      destroy: false,
      createMember: status === BudgetStatus.Active,
    };

    await db.budgets.update(id, {
      ...(status && { status }),
      ...(name && { name }),
      ...(purpose && { purpose }),
      ...(Array.isArray(limits) && { limits }),
      permissions,
    });

    const updatedBudget = await db.budgets.where('id').equals(id).first();

    await delay(500);
    return HttpResponse.json(updatedBudget);
  },
);

export const updateBudgetUser = http.patch(
  `${baseUrl}/karta/budget/:budgetId/user/:userId/`,
  async ({ request, params }) => {
    const { limits, role } = (await request.json()) as BudgetUser;
    const userId = Number(params.userId);
    const budgetId = Number(params.budgetId);
    const budgetUser = (await db.budgetUsers
      .where(['budget.id', 'user.id'])
      .equals([budgetId, userId])
      .first()) as BudgetUser;

    const budgetUserId = Number(budgetUser.id);

    await db.budgetUsers.update(budgetUserId, {
      ...(Array.isArray(limits) && { limits }),
      ...(role && { role }),
    });

    const updatedBudgetUser = await db.budgetUsers.get(budgetUserId);

    await delay(500);
    return HttpResponse.json(updatedBudgetUser);
  },
);

export const deleteBudgetUser = http.delete(
  `${baseUrl}/karta/budget/:budgetId/user/:userId/`,
  async ({ params }) => {
    const userId = Number(params.userId);
    const budgetId = Number(params.budgetId);
    const budget = await db.budgets.get(budgetId);
    const budgetUserInBudgets = await db.budgetUsersInBudgets.get({
      userId,
      budgetId,
    });
    const budgetUser = (await db.budgetUsers
      .where(['budget.id', 'user.id'])
      .equals([budgetId, userId])
      .first()) as BudgetUser;

    await db.budgetUsers.delete(Number(budgetUser.id));
    await db.budgetUsersInBudgets.delete(budgetUserInBudgets?.id as number);
    await db.budgets.update(budgetId, {
      userCount: (budget?.userCount as number) - 1,
    });
    const companyUser = (await db.companyUsers
      .where('user.id')
      .equals(userId)
      .first()) as CompanyUser;

    await db.companyUsers.update(Number(companyUser.id), {
      budgetUsers: companyUser?.budgetUsers?.filter(
        item => item?.budget?.id !== budgetId,
      ),
    });

    await delay(500);
    return HttpResponse.json();
  },
);
