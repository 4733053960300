import { faker } from '@faker-js/faker';

import { genLimits, getRandomItemFromArray } from '@/mocks';
import { AllSeedsData } from '@/seeds';

import {
  BudgetLimitType,
  BudgetStatus,
  BudgetUserRole,
  Currency,
} from '@/enums';
import type {
  Budget,
  BudgetLimit,
  BudgetSimple,
  BudgetUser,
  BudgetUserInBudget,
  CompanyAccount,
  CoreUser,
} from '@/interfaces';

export interface BudgetSeedDto extends Omit<Budget, 'id'> {
  id?: number;
}

const genBudgetUserId = (
  currentBudgetUsersIds: number[],
  usersCount: number,
): number => {
  const id = faker.number.int({ min: 1, max: usersCount });
  if (currentBudgetUsersIds.includes(id)) {
    return genBudgetUserId(currentBudgetUsersIds, usersCount);
  }
  return id;
};

export function genBudgets(
  usersCount: number,
  coreUsers: CoreUser[],
  companyAccount: CompanyAccount,
): {
  budgets: BudgetSeedDto[];
  budgetUsers: BudgetUser[];
  budgetUsersInBudgets: BudgetUserInBudget[];
} {
  const budgets: Budget[] = [];
  const budgetUsers: BudgetUser[] = [];
  const budgetUsersInBudgets: BudgetUserInBudget[] = [];

  AllSeedsData.budgets.forEach((item, i) => {
    const budgetSimple: BudgetSimple = {
      id: i + 1,
      status:
        Math.floor(Math.random() * 10) > 3
          ? BudgetStatus.Active
          : (getRandomItemFromArray(
              Object.values(BudgetStatus),
            ) as BudgetStatus),
      name: item.name,
      purpose: faker.commerce.productDescription(),
      currency: Currency.Usd,
    };

    const budgetUsersCount = faker.number.int({ min: 1, max: 30 });

    const budgetUsersIds: number[] = [];

    for (let j = 1; j <= budgetUsersCount; j += 1) {
      const currentBudgetUserId = genBudgetUserId(budgetUsersIds, usersCount);

      const role =
        i === 1
          ? BudgetUserRole.Manager
          : (getRandomItemFromArray([
              BudgetUserRole.Member,
              BudgetUserRole.Manager,
            ]) as BudgetUserRole);

      const budgetUser: BudgetUser = {
        budget: budgetSimple,
        user: coreUsers.find(
          item => item.id === currentBudgetUserId,
        ) as CoreUser,
        role,
        limits: genLimits(Object.values(BudgetLimitType)) as BudgetLimit[],
        spend: faker.commerce.price({ min: 100, max: 20000 }),
        permissions: {
          read: true,
          update: true,
          destroy: true,
          updateToManager: true,
          updateToMember: true,
        },
        createdAt: new Date(),
      };

      budgetUsersIds.push(currentBudgetUserId);

      budgetUsers.push(budgetUser);
      budgetUsersInBudgets.push({
        userId: budgetUser?.user?.id,
        budgetId: i + 1,
      });
    }

    budgets.push({
      ...budgetSimple,
      userCount: budgetUsersIds.length,
      spend: faker.commerce.price({ min: 100, max: 2000000 }),
      managers: [],
      companyAccount,
      limits: genLimits(Object.values(BudgetLimitType)) as BudgetLimit[],
      currency: Currency.Usd,
      createdAt: new Date(),
      permissions: {
        read: true,
        update: true,
        createMember: budgetSimple.status === BudgetStatus.Active,
        destroy: false,
      },
      cardIssuingReason: null,
    });
  });

  return {
    budgets,
    budgetUsers,
    budgetUsersInBudgets,
  };
}
